<template>
    <div>
        <!-- enable when it is needed -->
        <!-- <v-dialog v-if="two_factor_authentication(this.is_otp_enabled)" v-model="dialog" max-width="600">
            <v-card>
                <v-card-title>2-Factor-Authentication</v-card-title>
                    <v-card-text>
                    To enhance your account security and comply with recent legal changes, please enable Two-Factor Authentication (2FA) at your earliest convenience. Thank you!
                    </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="primary" @click="redirect2fa()">Enable 2FA</v-btn>
                    <v-btn color="secondary" @click="this.dialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
    <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(tile, key) in visible_tiles" :key="key">
        <v-card class="pa-3 mt-3">
            <div class="d-flex justify-space-between">
            <v-sheet color="primary" elevation="6" rounded width="auto" height="94" class="relative mt-n7">
                <v-icon large color="white" class="pa-7">{{ tile.title_icon }}</v-icon>
            </v-sheet>
            <div class="tile">
                <div class="tile_title">
                {{ tile.title }}
                </div>
                <v-progress-linear
                v-if="tile.content.length === 0"
                indeterminate
                height="10"
                color="primary"
                class="mt-2"
                ></v-progress-linear>
                <div v-else class="tile_content" v-for="(content, key) in tile.content" :key="key">
                {{ content }}
                </div>
            </div>
            </div>
        </v-card>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="4" v-for="(card, key) in visible_cards" :key="key">
        <v-card class="pa-3 mt-3">
            <div class="d-flex justify-start">
            <v-sheet color="primary" elevation="6" rounded width="100%" class="relative mt-n7">
                <v-icon large color="white" class="float-left pa-7">{{ card.title_icon }}</v-icon>
                <div class="ml-7 mt-6">
                <div class="body-1 white--text">
                    {{ card.title }}
                </div>
                <div class="body-2 white--text font-weight-light">{{ card.description }}</div>
                </div>
            </v-sheet>
            </div>
            <div v-if="card.template === 'changelog'">
            <v-timeline dense clipped class="mt-3 pr-3">
                <v-timeline-item v-for="(item, key) in card.content" :key="key" small>
                <v-card>
                    <v-card-text>
                    <v-chip
                        v-if="item.new"
                        x-small
                        class="font-weight-bold mr-1"
                        color="primary"
                        :ripple="false"
                        text-color="white"
                    >
                        <v-icon small left> mdi-alert-decagram </v-icon>
                        New
                    </v-chip>
                    <v-chip x-small color="secondary" :ripple="false" class="font-weight-bold mr-1">
                        V {{ item.version }}
                    </v-chip>
                    {{ item.content }}
                    </v-card-text>
                </v-card>
                </v-timeline-item>
            </v-timeline>
            </div>
            <div v-else-if="card.template === 'pending'">
            <v-card-text class="pb-0">
                <p>
                Thank you for requesting your customer-account on the Wirelesslogic BeNeLux Dashboard! Before you
                are able to see and manage your sim cards, a Wirelesslogic BeNeLux employee will first need to
                validate your account and match it to your sim cards. For security reasons, this might
                take some time to confirm and validate, but it will be processed faster during office
                hours.
                </p>
                <p>
                We will send you an email to inform you when the account is validated. From that point
                on you will be able to see and manage your sim cards, bundles and invoices in our
                Dashboard.
                </p>
            </v-card-text>
            </div>
            <div v-else-if="card.template === 'help'">
            <v-tabs
                grow
                center-active
                show-arrows
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                slider-color="secondary"
                class="pt-4"
            >
                <v-tab v-if="showHelp('simcards')"> Simcards </v-tab>
                <v-tab v-if="showHelp('smartsims')"> SmartSims </v-tab>
                <v-tab v-if="showHelp('bundles')"> Bundles </v-tab>
                <v-tab v-if="showHelp('switch')"> Network Switch </v-tab>
                <v-tab v-if="showHelp('diagnostics')"> Diagnostics </v-tab>
                <v-tab v-if="showHelp('downloads')"> Downloads </v-tab>

                <v-tab-item v-if="showHelp('simcards')">
                <v-card flat>
                    <v-card-text>
                    <p>
                        An overview of all your sim cards where you can find the most important
                        information about them; from types of usage, mobile number, the fixed IP
                        address and sim card status to the date when the subscription started and
                        when it ends.
                    </p>
                    <ul>
                        <li>Choose which columns you want to show or hide</li>
                        <li>
                        Use the filters or search options to find the exact sim card or
                        reference that you are looking for
                        </li>
                        <li>
                        Select one or more sim cards to place custom references or to send in a
                        network switch
                        </li>
                    </ul>
                    </v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item v-if="showHelp('smartsims')">
                <v-card flat>
                    <v-card-text>
                    <p>
                        By clicking the SmartSim icon in the menu on the left you can find all the
                        information related to your SmartSims.
                    </p>
                    <p>
                        The Overview page contains all the activated SmartSims in your account. Here
                        you can see from which provider they are, how many credits are left, the
                        percentage of usage that has taken place on it since the last time you
                        updated the credit amount, and you can place notes for your own
                        administration here.
                    </p>
                    <ul>
                        <li>
                        To activate a new SmartSim, go to the Activate page and enter the unique
                        activation code that was included with your prepaid sim card.
                        </li>
                        <li>
                        To see the amount of credits left in your wallet or to buy more credits,
                        visit our Wallet page.
                        </li>
                        <li>
                        Please contact Sim Services support in case you have any remaining
                        questions.
                        </li>
                    </ul>
                    </v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item v-if="showHelp('bundles')">
                <v-card flat>
                    <v-card-text>
                    <p>
                        Here you can see all the different data bundles on your sim cards, how many
                        active sim cards are part of that bundle, what the total data usage of those
                        sim cards is, and how much % of the total data bundle pool has been used
                        this month.
                    </p>
                    <p>
                        Per bundle you can click on the Action button to see exactly which sim cards
                        are configured with it, and which sim cards have the most usage. This way,
                        you can spot any unexpectedly high usage in time.
                    </p>
                    </v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item v-if="showHelp('switch')">
                <v-card flat>
                    <v-card-text>
                    <p>
                        Is there a connectivity issue in one device, or is a provider disruption
                        affecting multiple devices? We offer the option to force your device to
                        switch to a specific network in The Netherlands, Belgium, Germany or the
                        United Kingdom by temporarily changing the sim card profile.
                    </p>
                    <p>
                        Insert the ICCID number(s) of the sim card(s), select the country and
                        preferred network, and choose how long this sim card profile should be
                        changed before returning to its regular full roaming profile.<br />
                        The device with the sim card will disconnect from its current operator to go
                        look for the operator you selected. It could take one to several minutes for
                        this new connection to be made, depending on the coverage on location and
                        reception in the device. You can check the status of the connection in our
                        Diagnostics page.
                    </p>
                    </v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item v-if="showHelp('diagnostics')">
                <v-card flat>
                    <v-card-text>
                    <p>
                        On this page you can analyse the current status of the connection in your
                        device. Insert an ICCID number or select the Diagnostics option from the
                        Simcards page to start your diagnostic check. This will show useful sim card
                        information:
                    </p>
                    <ul>
                        <li>Usage details</li>
                        <li>Sim card configuration settings</li>
                        <li>Details about the last data session</li>
                        <li>Known errors</li>
                        <li>And for Tele2 cards exclusively; the last known general location</li>
                    </ul>
                    </v-card-text>
                </v-card>
                </v-tab-item>
                <v-tab-item v-if="showHelp('downloads')">
                <v-card flat>
                    <v-card-text>
                    <p>Here you can find downloadable overviews for your administration.</p>
                    <p>
                        Our Consolidated Usage overviews contain the total monthly usages per sim
                        card per price zone. For the best overview of your sim card usage history we
                        recommend downloading these files. Click the Generate File button to create
                        this downloadable overview.
                    </p>
                    </v-card-text>
                </v-card>
                </v-tab-item>
            </v-tabs>
            </div>
        </v-card>
        </v-col>
    </v-row>
    </div>
</template>
<script>
import { _isEmpty } from 'lodash';
import { mapState } from 'vuex';
import AccountEdit from '@/views/account/edit';
import { method } from 'lodash';

export default {
    data: () => ({
    dialog: false,
    tiles: [
        {
            visible: false,
            title: 'Active subscriptions',
            title_icon: 'mdi-sim',
            description: 'Last registration 26 minutes ago',
            description_icon: 'mdi-clock',
            index: 'active_subscriptions',
            show: [],
            content: [],
        },
        {
            visible: false,
            title: 'Simcard stock',
            title_icon: 'mdi-file-cabinet',
            description: 'Last registration 26 minutes ago',
            description_icon: 'mdi-clock',
            index: 'simcard_stock',
            show: [],
            content: [],
        },
        {
            visible: false,
            title: 'Total data usage',
            title_icon: 'mdi-chart-box',
            description: 'Last registration 26 minutes ago',
            description_icon: 'mdi-clock',
            index: 'data_usage_mtd',
            show: [{ append: ' MB' }],
            content: [],
        },
        {
            visible: false,
            title: 'Highest data usage',
            title_icon: 'mdi-trending-up',
            description: 'Last registration 26 minutes ago',
            description_icon: 'mdi-clock',
            index: 'top_simcard_data_usage',
            show: [{ index: 'iccid' }, { index: 'usage', append: ' MB' }],
            content: [],
        },
        {
            visible: false,
            title: 'Highest bundle usage',
            title_icon: 'mdi-view-dashboard',
            description: 'Last registration 26 minutes ago',
            description_icon: 'mdi-clock',
            index: 'top_bundle_usage',
            show: [{ index: 'bundle' }, { index: 'percentage', append: '%' }],
            content: [],
        },
    ],
    cards: [
        {
            visible: false,
            title: 'Changelog',
            title_icon: 'mdi-format-list-bulleted-square',
            description: 'New additions and fixes for your dashboard.',
            index: 'changelog',
            template: 'changelog',
            content: [
                {
                version: '1.4.1',
                content: 'Two Factor Authentication added to the login process.',
                new: true,
                },
                {
                version: '1.4.0',
                content: 'Dashboard theme changed to match the Wireless Logic Benelux style.',
                },
                {
                version: '1.3.11',
                content: 'New option in actions for LoRa devices to directly send a downlink payload.',
                },
                {
                version: '1.3.10',
                content: 'Triggers can now be set on simcards.',
                },
                {
                version: '1.3.9',
                content: 'Blocks can now be refreshed individually in Diagnostics Scan.',
                },
            ],
        },
        {
            visible: false,
            title: 'Help',
            title_icon: 'mdi-help-circle',
            description: 'Learn about all different sections.',
            index: 'help',
            template: 'help',
        },
        {
            visible: false,
            hide_for_admin: true,
            title: 'Validation pending',
            title_icon: 'mdi-account-search',
            description: 'We are validating your new account.',
            index: 'pending',
            template: 'pending',
        },
    ],
    }),
    methods: {
        redirect2fa(){
            return this.$router.push('/account')
        },
        two_factor_authentication(item){
            if (item == false){
                return this.dialog=true
            }
            else{
                return this.dialog=false
            }
        },
        isNotEmpty(obj) {
            return !_isEmpty(obj);
        },
        showHelp(page) {
            let views = this.$store.getters['session/rules'].views;
            if (typeof views !== 'undefined') {
            if (views === 'all' || views.includes(page)) {
                return true;
            }
            }
            return false;
        },
        do_math(value, equation) {
            let decimals = 2;
            if (equation.length === 3) {
            decimals = equation[2];
            }
            switch (equation[0]) {
            case '+':
                return (value + equation[1]).toFixed(decimals);
            case '-':
                return (value - equation[1]).toFixed(decimals);
            case '/':
                return (value / equation[1]).toFixed(decimals);
            case '*':
                return (value * equation[1]).toFixed(decimals);
            case ':':
                return (value - (value %= 60)) / 60 + (9 < value ? ':' : ':0') + value;
            default:
                return value;
            }
        },
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        ...mapState('session', {
            access_tiles: (state) => state.rules.home || false,
            is_otp_enabled: (state) => state.login_success || false
        }),
        visible_tiles() {
            return this.tiles.filter((h) => h.visible === true);
        },
        visible_cards() {
            return this.cards.filter((h) => h.visible === true);
        },
        },
        created() {
            this.dialog = !this.is_otp_enabled;
            if (this.access_tiles) {
                for (let i = 0; i < this.tiles.length; i++) {
                this.tiles[i].visible =
                    this.access_tiles === 'all' || this.access_tiles.includes(this.tiles[i].index);
                }
                for (let i = 0; i < this.cards.length; i++) {
                if (this.access_tiles === 'all') {
                    if (!this.cards[i].hide_for_admin) {
                    this.cards[i].visible = true;
                    }
                } else if (this.access_tiles.includes(this.cards[i].index)) {
                    this.cards[i].visible = true;
                } else {
                    this.cards[i].visible = false;
                }
                }

                this.$store
                .dispatch('api/call', {
                    url: '/statistics',
                })
                .then(
                    () => {
                    for (const [key, item] of Object.entries(this.payload)) {
                        let tile = this.tiles.find((obj) => obj.index === key);
                        if (item != null) {
                            let payload_value = item;
                        if (tile.show.length === 0) {
                            tile.content.push(item);
                        } else {
                            for (let i = 0; i < tile.show.length; i++) {
                            if (typeof tile.show[i].index === 'string') {
                                payload_value = item[tile.show[i].index];
                            }
                            if ('math' in tile.show[i]) {
                                payload_value = this.do_math(payload_value, tile.show[i].math);
                            }
                            if ('append' in tile.show[i]) {
                                payload_value += tile.show[i].append;
                            }
                            tile.content.push(payload_value);
                            }
                        }
                        } else {
                        tile.content.push('');
                        }
                    }
                    },
                    (error) => {
                    console.log('Error fetching statistics:', error);
                    }
                );
            }
    },
};
</script>
<style scoped>
    .tile {
        text-align: right;
    }
    .tile_title {
        font-weight: 600;
        font-size: 1em;
        margin-bottom: 4px;
    }
    .tile_content {
        font-size: 0.9em;
        line-height: 1.2em;
    }
</style>