<template>
    <div>
        <h1 class="mb-4">Bulk Changes</h1>
                    <v-alert
                    v-if="customer_specials.length"
                    icon="mdi-alert"
                    dense
                    border="left"
                    type="warning"
                    class="ma-3"
                    height="auto" width="800"
                    >
                    <div v-for="(special, index) in customer_specials" :key="index">
                        <h3>{{ special.category }} - {{ special.subcategory }}</h3>
                        <div v-for="(line, i) in ensureArray(special.specials)" :key="i">
                            <ul>
                                <li>
                                    {{ line }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    </v-alert>
        <v-row>
            <v-form ref="bulk_changes">
                <v-card dark color="secondary" class="ma-6" height="auto" width="800">

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="customer_id"
                                    :items="customers.payload"
                                    label="Select customer"
                                    item-value="id"
                                    item-text="customer_name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-model="operator"
                                    :items="operators"
                                    label="Select operator"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-model="bulk_type"
                                    :items="bulk_types"
                                    label="Select input"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="bulk_type == 'custom'" cols="12" sm="6">
                                <v-select
                                    v-if="bulk_type == 'custom'"
                                    v-model="custom_field"
                                    :items="custom_fields"
                                    label="Select the Custom Field from which to change from"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                >
                                </v-select>
                            </v-col>
                            <v-col v-if="bulk_type == 'custom'" cols="12" sm="6">
                                <v-text-field
                                    v-if="bulk_type == 'custom'"
                                    v-model="custom_value"
                                    label="The customfield text(value) which to change from"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-textarea
                                    v-if="bulk_type == 'list'"
                                    v-model="list"
                                    label="ICCIDs separeted by comma or space or enter"
                                    :rules="[rules.required, rules.list]"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-textarea>

                                <v-autocomplete
                                    v-if="bulk_type == 'subscription'"
                                    v-model="old_subscription_id"
                                    :items="getSubscriptions"
                                    label="Old subscription"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="action"
                                    :items="action_types"
                                    label="The change type"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                    :disabled="!customer_id || !operator"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="action == 'status'" cols="12" sm="6">
                                <v-autocomplete
                                    v-model="status"
                                    :items="status_items"
                                    :disabled="!customer_id || !operator"
                                    label="Simcard Status"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="action == 'subscription' || action == 'test_to_bundle'" cols="12" sm="6">
                                <v-autocomplete
                                    v-model="new_subscription_id"
                                    :items="getSubscriptions"
                                    :disabled="!customer_id || !operator"
                                    label="New subscription"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="action == 'customer'" cols="12" sm="6">
                                <v-autocomplete
                                    v-model="new_customer_id"
                                    :items="customers.payload"
                                    label="Select new customer"
                                    item-value="id"
                                    item-text="customer_name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col v-if="action == 'ip_range'" cols="12" sm="6">
                                <v-select
                                    label="Select IP Solution"
                                    v-model="selected_iprange"
                                    :items="ipRanges"
                                    item-text="customer_range"
                                    item-value="customer_range"
                                    :rules="[rules.required]"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :disabled="!customer_id || !operator"
                                >
                                    <template v-slot:item="{ item }">
                                        <span :style="{ color: item.color }"
                                            >{{ item.customer_range }} (Free IPs: {{ item.free_ips }})</span
                                        >
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="action == 'customer'">
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="new_subscription_id"
                                    :items="newSubscriptions"
                                    :disabled="!new_customer_id || !operator"
                                    label="New subscription"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row v-if="status == 'end_date'">
                            <v-col cols="12" sm="6">
                                <v-menu
                                    v-model="date_picker"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:[`activator`]="{ on }">
                                        <v-text-field
                                            v-model="end_date"
                                            label="Retire Date"
                                            hint="When should this simcard automatically be retired?"
                                            readonly
                                            persistent-hint
                                            outlined
                                            dense
                                            hide-details="auto"
                                            clearable
                                            v-on="on"
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="end_date"
                                        no-title
                                        scrollable
                                        :min="new Date().toISOString().substr(0, 10)"
                                        @input="date_picker = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn @click="processChange" color="primary">SUBMIT</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-row>
        <v-dialog v-model="dialog.state" max-width="800">
            <v-card>
                <v-toolbar :color="dialog.color" dark>
                    <v-card-title>Change the Customer in operator portal!</v-card-title>
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{ dialog.message }}
                    <v-row>
                        <v-textarea
                            v-if="dialog.sub_message.length > 0"
                            v-model="dialog.sub_message"
                            label="ICCIDs to copy separated by comma"
                            outlined
                            dense
                            hide-details="auto"
                            class="mt-4"
                        >
                        </v-textarea>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="warning" @click="reset">CLOSE</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            centered
            top
            :color="snackbar.color"
            v-model="snackbar.state"
            :timeout="snackbar.timeout"
            :multi-line="snackbar.multiLine"
        >
            {{ snackbar.message }}
            <template v-if="snackbar.persistent" v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            customers: (state) => state.calls.customer_list,
            subscriptions: (state) => state.calls.subscription_list,
        }),
        customer_operator() {
            return `${this.customer_id}|${this.operator}`
        },
        getSubscriptions() {
            var subscriptions = this.subscriptions.payload.filter((obj) => {
                return (
                    obj.customer.id === this.customer_id &&
                    obj.operator === this.operator &&
                    obj.bundle_type !== 'SMART'
                );
            });

            return subscriptions.map((element) => {
                let modifiedName = element.name;

                if (element.sms && element.voice) {
                    modifiedName += " (sms+voice)";
                } else if (element.sms) {
                    modifiedName += " (sms)";
                } else if (element.voice) {
                    modifiedName += " (voice)";
                }

                return {
                    ...element,
                    name: modifiedName
                };
            });
        },
        newSubscriptions() {
            var subscriptions = this.subscriptions.payload.filter((obj) => {
                return (
                    obj.customer.id === this.new_customer_id &&
                    obj.operator === this.operator &&
                    obj.bundle_type !== 'SMART'
                );
            });

            return subscriptions.map((element) => {
                let modifiedName = element.name;

                if (element.sms && element.voice) {
                    modifiedName += " (sms+voice)";
                } else if (element.sms) {
                    modifiedName += " (sms)";
                } else if (element.voice) {
                    modifiedName += " (voice)";
                }

                return {
                    ...element,
                    name: modifiedName
                };
            });
        },
        ipRanges() {
            var ip_ranges = []
            // First calculate the default IP ranges: SimServices and TestVPN
            var default_obj = this.customers.payload.find((o) => o.id === 250)
            var default_ipranges = _.filter(default_obj.ip_ranges, function (o) {
                return !o.free_ips == 0
            })
            default_ipranges = _.filter(default_ipranges, { operator: this.operator })
            for (var i = 0; i < default_ipranges.length; i++) {
                if (Number(default_ipranges[i].free_ips) < 25) {
                    default_ipranges[i].color = 'red'
                }
            }

            ip_ranges = ip_ranges.concat(default_ipranges)

            // If this a Sim Services Test customer, find all.
            if (this.customer_id == 250) {
                var all_ranges = []
                var customers = this.customers.payload.forEach((o) => {
                    var range = _.filter(o.ip_ranges, (i) => {
                        return !i.free_ips == 0
                    })
                    if (range.length > 0) {
                        if (range.length == 1) {
                            all_ranges.push(range[0])
                        }
                        if (range.length > 1) {
                            range.forEach((o) => {
                                all_ranges.push(o)
                            })
                        }
                    }
                })
                var customer_ipranges = _.filter(all_ranges, { operator: this.operator })
                for (var i = 0; i < customer_ipranges.length; i++) {
                    if (Number(customer_ipranges[i].free_ips) < 25) {
                        customer_ipranges[i].color = 'red'
                    }
                }
                ip_ranges = ip_ranges.concat(customer_ipranges)
            }
            // Then get all the customer specific IP ranges
            if (this.customer_id) {
                var customer_obj = this.customers.payload.find((o) => o.id === this.customer_id)
                var customer_ipranges = _.filter(customer_obj.ip_ranges, function (o) {
                    return !o.free_ips == 0
                })
                customer_ipranges = _.filter(customer_ipranges, { operator: this.operator })
                for (var i = 0; i < customer_ipranges.length; i++) {
                    if (Number(customer_ipranges[i].free_ips) < 25) {
                        customer_ipranges[i].color = 'red'
                    }
                }
                ip_ranges = ip_ranges.concat(customer_ipranges)
            }
            return ip_ranges
        },
    },
    watch: {
        customer_operator() {
            if (this.customer_id && this.operator) {
            }
        },
        bulk_type() {
            this.old_subscription_id = ''
            this.list = ''
            this.selected_custom = ''
            this.custom_value = ''
        },
        customer_id() {
            if (this.customer_id) {
                let customer = this.customers.payload.find((object) => object.id === this.customer_id)
                this.customer_specials = customer.specials
            }
        },
    },
    data() {
        return {
            dialog: {
                state: false,
                message: '',
                sub_message: '',
                color: '',
            },
            date_picker: false,
            customer_id: '',
            new_customer_id: '',
            customer_specials: [],
            selected_iprange: '',
            new_subscription_id: '',
            old_subscription_id: '',
            bulk_type: 'list',
            list: '',
            operator: '',
            custom_field: '',
            custom_value: '',
            custom_fields: [
                { id: 1, name: 'Custom 1' },
                { id: 2, name: 'Custom 2' },
                { id: 3, name: 'Custom 3' },
                { id: 4, name: 'Custom 4' },
                { id: 5, name: 'Custom 5' },
            ],
            bulk_types: [
                { name: 'By ICCID(s)', id: 'list' },
                { name: 'By Subscription', id: 'subscription' },
                { name: 'By Custom Field', id: 'custom' },
            ],
            operators: [
                { name: 'Tele2', id: 'tele2' },
                { name: 'KPN Roosendaal', id: 'kpn_rd' },
                { name: 'KPN Eindhoven', id: 'kpn_eh' },
                { name: 'Telenor', id: 'telenor' },
                { name: 'T-mobile', id: 't_mobile' },
                { name: 'Vodafone', id: 'vodafone' },
                { name: 'Conexa London', id: 'conexa_ld'},
                { name: 'Conexa Oslo', id: 'conexa_os'},
            ],
            action: 'subscription',
            action_types: [
                { name: 'Subscription Change', id: 'subscription' },
                { name: 'Status Change', id: 'status' },
                { name: 'Customer Change', id: 'customer' },
                { name: 'IP Solutions Change', id: 'ip_range' },
                { name: 'Test to Bundle Change', id: 'test_to_bundle' },
            ],
            status: 'activate',
            status_items: [
                { name: 'Activate', id: 'activate' },
                { name: 'Deactivate', id: 'deactivate' },
                { name: 'Retire Now', id: 'retire' },
                { name: 'Retire on Date', id: 'end_date' },
                { name: 'Retire Lost / Unused Simcards', id: 'lost' },
            ],
            end_date: '',
            rules: {
                required: (value) => !!value || 'Required!',
                list: (value) => !value || this.validateIccids(value) || 'Invalid ICCID in list!',
            },
            snackbar: {
                state: false,
                color: '',
                message: '',
                timeout: 5000,
                multiline: true,
                persistent: false,
            },
        }
    },
    methods: {
        operatorCode(operator_sid) {
            const operators = {
                tele2: '2POM',
                kpn_rd: 'KPOM',
                kpn_eh: 'KPOM',
                telenor: 'TPOM',
                t_mobile: 'TMPOM',
                vodafone: 'VODA',
            }

            return operators[operator_sid]
        },
        /**
         * Splits a special field (which can be a string or an array of strings)
         * using newline and comma as delimiters, trims each part, and filters out empty strings.
         *
         * @param {string|Array} input - The special field value to split.
         * @return {Array} An array of split lines.
         */
        ensureArray(input) {
            if (!input) return [];
            
            // If the input is a string, wrap it in an array.
            let specialsArray = typeof input === 'string' ? [input] : input;

            // Process each string in the array.
            return specialsArray
        },
        validateIccids(list) {
            var valid = false
            if (list) {
                var list = list
                    .split(/\s*[\n, ]+\s*/)
                    .join()
                    .split(',')

                if (list.every((element) => /^\d+$/.test(element) === true)) {
                    valid = true
                }
            }
            return valid
        },
        toggleSnackbar(persistent, color, message) {
            this.snackbar.state = true
            this.snackbar.color = color
            this.snackbar.message = message

            if (persistent == true) {
                this.snackbar.persistent = true
                this.snackbar.timeout = 0
            }
        },
        reset() {
            if (this.dialog.state) {
                this.dialog.state = false
                this.dialog.message = ''
                this.dialog.sub_message = ''
                this.dialog.color = ''
            }
            this.customer_id = ''
            this.new_subscription_id = ''
            this.old_subscription_id = ''
            this.bulk_type = 'list'
            this.list = ''
            this.operator = ''
            this.selected_custom = ''
            this.custom_value = ''
            this.action = 'subscription'
            this.new_customer_id = ''
            this.$refs.bulk_changes.resetValidation()
        },
        processChange() {
            if (this.$refs.bulk_changes.validate()) {
                var iccids = this.list
                if (iccids) {
                    iccids = iccids.split(/\s*[\n, ]+\s*/)
                    for (var i = 0; i < iccids.length; i++) {
                        if (iccids[i] === '') {
                            iccids.splice(i, 1)
                        }
                    }
                }

                const data = {
                    customer_id: this.customer_id,
                    operator: this.operator,
                    bulk_type: this.bulk_type,
                    action: this.action,
                }

                switch (this.action) {
                    case 'subscription':
                        data.new_subscription_id = this.new_subscription_id
                        break
                    case 'test_to_bundle':
                        data.new_subscription_id = this.new_subscription_id
                        break
                    case 'status':
                        data.action = this.status
                        if (this.status == 'end_date') {
                            data.end_date = this.end_date
                        }
                        break
                    case 'customer':
                        if (this.customer_id == this.new_customer_id) {
                            return this.toggleSnackbar(true, 'error', 'Current and New Customer are the same!')
                        }
                        data.new_customer_id = this.new_customer_id
                        data.new_subscription_id = this.new_subscription_id
                        break
                    case 'ip_range':
                        data.operator = this.operator
                        data.ip_range = this.selected_iprange
                        break
                }

                switch (this.bulk_type) {
                    case 'list':
                        data.iccids = iccids
                        break
                    case 'subscription':
                        if (this.old_subscription_id == this.new_subscription_id) {
                            return this.toggleSnackbar(true, 'error', 'Current and New subscription are the same!')
                        }
                        data.old_subscription_id = this.old_subscription_id
                        break
                    case 'custom':
                        data.custom_field = this.custom_field
                        data.custom_value = this.custom_value
                        break
                }
                
                console.log(data)
                this.$store
                    .dispatch('api/call', {
                        method: 'put',
                        url: '/root/bulk_change',
                        data: { ...data },
                    })
                    .then(
                        (response) => {
                            switch (response.status) {
                                case 202:
                                    this.dialog.state = true
                                    this.dialog.color = 'warning'
                                    this.dialog.message = response.data.description
                                    this.dialog.sub_message = response.data.payload
                                    break
                                default:
                                    this.toggleSnackbar(false, 'success', response.data.description)
                                    this.reset()
                            }
                        },
                        (error) => {
                            this.toggleSnackbar(true, 'error', this.payload.description)
                        }
                    )
            }
        },
    },
    created() {
        this.$store
            .dispatch('api/call', {
                name: 'customer_list',
                url: '/root/customers',
                params: {
                    only: 'id, customer_name, specials, ip_ranges',
                },
            })
            .then((response) => {
                this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))
                for (var i = 0; i < this.customers.payload.length; i++) {
                    this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']})`
                }
            })
        this.$store
            .dispatch('api/call', {
                name: 'subscription_list',
                url: '/root/subscriptions',
                params: { bundle_type_skip: 'TEST,NEW' },
            })
            .then((response) => {
                this.subscriptions.payload.sort((a, b) =>
                    a.customer.customer_name.localeCompare(b.customer.customer_name)
                )
            })
    },
}
</script>