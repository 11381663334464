<template>
    <div>
        <h1 class="mb-4">Order Products</h1>
        <v-row>
            <v-form ref="product_order">
                <v-card dark color="secondary" class="ml-6 mb-6" height="auto" width="700">
                    <v-card-text>
                        <v-alert v-if="!products_found" icon="mdi-alert" dark dense border="left" type="warning">
                            <v-row align="center">
                                <v-col class="grow">
                                    No <strong>{{ this.new_item.charge_type }}</strong> customer products were found!
                                    You can create a new product on the subscriptions page.
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn icon @click="gotoSubscriptions()">
                                        <v-icon>mdi-exit-to-app</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                        <v-alert
                        v-if="selected_customer && selected_customer.specials && selected_customer.specials.length > 0"
                        icon="mdi-alert"
                        dense
                        border="left"
                        type="warning"
                        >
                        <div v-for="(specialItem, index) in selected_customer.specials" :key="index">
                            <h3>{{ specialItem.category }} - {{ specialItem.subcategory }}</h3>
                            <div v-for="(line, i) in ensureArray(specialItem.specials)" :key="i">
                                <ul>
                                    <li>
                                        {{ line }}
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                        </v-alert>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="new_item.customer_id"
                                    :items="customers.payload"
                                    :disabled="disabled"
                                    label="Select customer"
                                    item-value="id"
                                    item-text="customer_name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select
                                    v-model="new_item.charge_type"
                                    :items="charge_types"
                                    Label="Select the correct charge type"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="new_item.product_id"
                                    :items="customer_products"
                                    label="Select the product type"
                                    item-value="id"
                                    item-text="name"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="new_item.quantity"
                                    label="Quantity of products"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :rules="[rules.required, rules.min, rules.number]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="new_item.remark"
                                    label="Additional information for this product order"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn
                            @click="validate_order()"
                            :disabled="(!this.new_item.customer_id, !this.new_item.product_id, !this.new_item.quantity)"
                            color="primary"
                            >ADD</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-form>
            <v-card dark color="secondary" class="mb-6 ml-6" height="auto" min-width="400" max-width="400">
                <v-card-title> <v-icon left>mdi-cart</v-icon>Products</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-list-item-group>
                        <v-list-item v-for="(item, i) in orders" :key="i">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.customer_name"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.product_name"></v-list-item-subtitle>
                                <v-list-item-subtitle v-text="item.charge_type"></v-list-item-subtitle>
                                <v-list-item-subtitle v-text="`€ ${item.price}`"></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn @click="remove(item)" icon>
                                    <v-icon color="grey lighten-1">mdi-close</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-card-text>
                <div v-if="this.orders.length > 0">
                    <v-divider class="mb-12"></v-divider>
                    <v-card-title class="justify-end">Total: €{{ total_price }}</v-card-title>
                    <v-card-actions class="justify-center">
                        <v-btn color="primary" @click="place_order()">SUBMIT</v-btn>
                    </v-card-actions>
                </div>
                <div v-else>
                    <v-card-text>No products selected yet...</v-card-text>
                </div>
            </v-card>
        </v-row>
        <v-snackbar
            centered
            top
            :color="snackbar.color"
            v-model="snackbar.state"
            :timeout="snackbar.timeout"
            :multi-line="snackbar.multiLine"
        >
            {{ snackbar.message }}
            <template v-if="snackbar.persistent" v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            customers: (state) => state.calls.customer_list,
            subscriptions: (state) => state.calls.subscriptions_list,
        }),
        customer_products() {
            if (this.new_item.customer_id !== '') {
                var customer_id = this.new_item.customer_id
                const products = this.subscriptions.payload.filter((object) => {
                    return object.customer_id === customer_id
                })

                var recurring = false
                if (this.new_item.charge_type == 'One-Off (Setup)') {
                    recurring = false
                } else {
                    recurring = true
                }

                const products_filtered = products.filter((object) => {
                    return object.product_type.recurring === recurring
                })
                if (products_filtered.length == 0) {
                    this.products_found = false
                } else {
                    this.products_found = true
                }
                return products_filtered
            }
        },
        total_price() {
            const pricing = []
            this.orders.forEach((item) => {
                var price = item.price
                pricing.push(price)
            })
            var total = 0
            for (var i = 0; i < pricing.length; i++) {
                total = parseFloat(total) + parseFloat(pricing[i])
            }
            return total
        },
    },
    watch: {
        charge_type() {
            this.price = ''
            this.product_name = ''
        },
        orders() {
            console.log(this.orders)
            if (this.orders.length == 0) {
                this.disabled = false
            }
        },
        'new_item.customer_id'(newVal) {
            if (newVal) {
            // Find the customer object that matches the selected id.
            const customer = this.customers.payload.find(item => item.id === newVal);
            if (customer) {
                // Set selected_customer to the found customer.
                this.selected_customer = customer;
                // Assuming that the customer object contains a 'specials' property which is an array,
                // update customer_specials accordingly.
                this.customer_specials = customer.specials ? customer.specials : [];
            } else {
                // If no customer is found, clear both.
                this.selected_customer = '';
                this.customer_specials = [];
            }
            } else {
            // No customer id selected: clear both.
            this.selected_customer = '';
            this.customer_specials = [];
            }
        },
    },
    data() {
        return {
            order_valid: true,
            is_loading: false,
            disabled: false,
            submit_disabled: true,
            selected_customer: '',
            product_name: '',
            selected_type: '',
            recuring: false,
            customer_specials: [],
            charge_type: 'One-Off (Setup)',
            charge_types: ['One-Off (Setup)', 'Recurring'],
            products_found: true,
            rules: {
                required: (value) => !!value || 'Required!',
                min: (value) => value > 0 || 'Quantity must be at least 1!',
                number: (value) => {
                    const pattern = /^\d*(\.\d{1,2})?$/
                    return pattern.test(value) || 'Can only contain numbers!'
                },
                currency: (value) => {
                    const pattern = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/
                    return pattern.test(value) || 'Invalid currency format!'
                },
            },
            new_item: {
                customer_id: '',
                product_id: '',
                charge_type: 'One-Off (Setup)',
                product_name: '',
                quantity: 1,
                remark: '',
                price: '',
            },
            orders: [],
            snackbar: {
                state: false,
                color: '',
                message: '',
                timeout: 5000,
                multiline: true,
                persistent: false,
            },
        }
    },
    methods: {
        gotoSubscriptions() {
            this.$router.push({ path: `/products/subscriptions` })
        },
        toggleSnackbar(persistent, color, message) {
            this.snackbar.state = true
            this.snackbar.color = color
            this.snackbar.message = message

            if (persistent == true) {
                this.snackbar.persistent = true
                this.snackbar.timeout = 0
            }
        },
                /**
         * Splits a special field (which can be a string or an array of strings)
         * using newline and comma as delimiters, trims each part, and filters out empty strings.
         *
         * @param {string|Array} input - The special field value to split.
         * @return {Array} An array of split lines.
         */
        ensureArray(input) {
            if (!input) return [];
            
            // If the input is a string, wrap it in an array.
            let specialsArray = typeof input === 'string' ? [input] : input;

            // Process each string in the array.
            return specialsArray
        },
        generate_price(product_id) {
            const quantity = this.new_item.quantity
            const products = this.subscriptions.payload
            const product_price = products.filter((object) => {
                return object.id === product_id
            })
            const price = parseFloat(product_price[0].price) * quantity
            return (this.new_item.price = price.toFixed(2))
        },
        generate_name(product_id) {
            const products = this.subscriptions.payload
            const product_name = products.filter((object) => {
                return object.id === product_id
            })
            return (this.product_name = product_name[0].name)
        },
        customer_name(customer_id) {
            const customers = this.customers.payload
            const customer = customers.filter((object) => {
                return object.id === customer_id
            })
            return customer[0].customer_name
        },
        validate_order() {
            if (this.$refs.product_order.validate()) {
                this.order_valid = true
                this.is_loading = true

                this.new_item.product_name = this.generate_name(this.new_item.product_id)
                this.new_item.price = this.generate_price(this.new_item.product_id)
                this.new_item.customer_name = this.customer_name(this.new_item.customer_id)

                const data = this.new_item
                this.orders.push(data)
                this.$refs.product_order.resetValidation()
                this.disabled = true
                this.is_loading = false
                this.new_item = {
                    customer_id: data.customer_id,
                    product_id: '',
                    charge_type: 'One-Off (Setup)',
                    quantity: 1,
                    remark: '',
                    price: '',
                }
            } else {
                this.order_valid = false
                this.is_loading = false
            }
        },
        remove(item) {
            const index = this.orders.indexOf(item)
            this.orders.splice(index, 1)
        },
        place_order() {
            // submit code
            const data = this.orders

            data.forEach((object) => {
                delete object['charge_type']
                delete object['customer_name']
                delete object['price']
                delete object['product_name']
            })

            this.$store
                .dispatch('api/call', {
                    method: 'post',
                    url: '/root/products/order',
                    data: data,
                })
                .then(
                    (response) => {
                        this.toggleSnackbar(false, 'success', 'New product order(s) submitted succesfully!')
                        this.orders = []
                    },
                    (error) => {
                        console.log(this.payload)
                        this.toggleSnackbar(true, 'error', this.payload.description)
                        this.orders = []
                    }
                )
        },
    },
    created() {
        this.$store
            .dispatch('api/call', {
                name: 'customer_list',
                url: '/root/customers',
                params: {
                    only: 'id, customer_name, specials',
                },
            })
            .then((response) => {
                this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))
                for (var i = 0; i < this.customers.payload.length; i++) {
                    this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']}) `
                }
            }),
        this.$store
            .dispatch('api/call', {
                name: 'subscriptions_list',
                url: '/root/products',
            })
            .then((response) => {
                this.subscriptions.payload.sort((a, b) => a.name.localeCompare(b.name))
            })
    },
}

</script>
