<template>
    <div>
        <v-snackbar v-model="snackbar">
            {{ snackbar_message }}
            <v-btn color="primary" text @click="snackbar = false"> Close </v-btn>
        </v-snackbar>
        <v-dialog v-model="dialog_simcard" max-width="500">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Simcards</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 v-if="!['TMPOM'].includes(editedItem.operator)">
                                <v-select
                                    v-model="editedItem.status"
                                    :items="[
                                        { text: 'Activated', value: 2 },
                                        { text: 'Deactivated', value: 1 },
                                    ]"
                                    prepend-icon="mdi-sim"
                                    label="Status"
                                ></v-select>
                            </v-flex>
                            <v-flex xs12 v-if="!['TMPOM'].includes(editedItem.operator)">
                                <v-menu
                                    v-model="date_picker"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:[`activator`]="{ on }">
                                        <v-text-field
                                            v-model="editedItem.end_date"
                                            label="End Date"
                                            prepend-icon="mdi-calendar"
                                            hint="When should this simcard automatically be retired?"
                                            readonly
                                            persistent-hint
                                            clearable
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="editedItem.end_date"
                                        no-title
                                        scrollable
                                        :min="new Date().toISOString().substr(0, 10)"
                                        @input="date_picker = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12>
                                <div class="subtitle-1 mb-1 mt-7">
                                    <v-icon>mdi-file-document-edit</v-icon> Custom Fields
                                </div>
                            </v-flex>
                            <v-flex md12>
                                <v-text-field
                                    v-model="editedItem.custom_1"
                                    solo-inverted
                                    dense
                                    label="Custom 1"
                                    class="mb-n4 mr-md-2"
                                ></v-text-field>
                                <v-text-field
                                    v-model="editedItem.custom_2"
                                    solo-inverted
                                    dense
                                    label="Custom 2"
                                    class="mb-n4 mr-md-2"
                                ></v-text-field>
                                <v-text-field
                                    v-model="editedItem.custom_3"
                                    solo-inverted
                                    dense
                                    label="Custom 3"
                                    class="mb-n4 mr-md-2"
                                ></v-text-field>
                                <v-text-field
                                    v-model="editedItem.custom_4"
                                    solo-inverted
                                    dense
                                    label="Custom 4"
                                    class="mb-n4 mr-md-2"
                                ></v-text-field>
                                <v-text-field
                                    v-model="editedItem.custom_5"
                                    solo-inverted
                                    dense
                                    label="Custom 5"
                                    class="mb-n4 mr-md-2"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click.native="close">Close</v-btn>
                    <v-btn color="primary darken-1" text @click.native="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <h1 class="mb-4">Simcards</h1>

        <v-toolbar dense dark>
            <v-text-field
                name="searchfield"
                prepend-icon="mdi-table-search"
                v-model="search_global"
                v-on:input="debounceFilter(search_global)"
                :disabled="header_filters"
                clearable
                hide-details
            >
                <template v-slot:append-outer>
                    <v-dialog v-model="dialog_help" width="500">
                        <template v-slot:[`activator`]="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        <v-card>
                            <v-card-title> Filter Help </v-card-title>

                            <v-card-text>
                                <div class="font-weight-black">Contains <code>1234</code></div>
                                <div class="font-weight-thin mb-2">
                                    Default search method. Find values that include your search string.
                                </div>
                                <div class="font-weight-black">Starts With <code>1234*</code></div>
                                <div class="font-weight-thin mb-2">
                                    Use when you know what your value starts with, but not the exact text.
                                </div>
                                <div class="font-weight-black">Ends With <code>*1234</code></div>
                                <div class="font-weight-thin mb-2">
                                    Use when you know what your value ends with, but not the exact text.
                                </div>
                                <div class="font-weight-black">Equals <code>=1234</code></div>
                                <div class="font-weight-thin mb-2">Use for an exact match.</div>
                                <div class="font-weight-black">Not Equal To <code>!=1234</code></div>
                                <div class="font-weight-thin mb-2">
                                    Shows results that don’t have the value you enter.
                                </div>
                                <div class="font-weight-black">In <code>(123, 456, 789, 1011)</code></div>
                                <div class="font-weight-thin mb-2">
                                    Find results that include one or more of the values you enter.
                                </div>
                                <div class="font-weight-black">Not In <code>!(123, 456, 789, 1011)</code></div>
                                <div class="font-weight-thin mb-2">
                                    Find results that do not contain any values that match the ones entered.
                                </div>
                                <div class="font-weight-black">Between <code>[1, 100]</code></div>
                                <div class="font-weight-thin mb-2">Filter on a range of values.</div>
                                <div class="font-weight-black">Less Than <code>&lt;100</code></div>
                                <div class="font-weight-thin mb-2">
                                    Find entries that are less than the value you enter.
                                </div>
                                <div class="font-weight-black">Greater Than <code>&gt;1234</code></div>
                                <div class="font-weight-thin mb-2">Find entries that exceed the value you enter.</div>
                                <div class="font-weight-black">Less Or Equal <code>&lt;=100</code></div>
                                <div class="font-weight-thin mb-2">
                                    Use for results that match or are less than the value you enter.
                                </div>
                                <div class="font-weight-black">Greater Or Equal <code>&gt;=1234</code></div>
                                <div class="font-weight-thin mb-2">
                                    Use for results that match or exceed the value you enter.
                                </div>
                                <div class="font-weight-black">Equals or Not Equal To <code>=null / !=null</code></div>
                                <div class="font-weight-thin mb-2">
                                    Find entries where the value is equal or not equal to null.
                                </div>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialog_help = false"> Close </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </v-text-field>
            <v-spacer></v-spacer>

            <v-toolbar-items>
                <v-menu offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on" :disabled="toggleActionsButton">
                            <v-icon left>mdi-ballot-outline</v-icon>
                            Actions
                        </v-btn>
                    </template>
                    <v-list v-if="selected.length == 1" dense>
                        <v-list-item-group color="primary">
                            <v-list-item @click="gotoScan(selected[0].iccid)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-magnify-scan</v-icon>
                                        Run Scan
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="gotoNetworkSwitch()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-satellite-uplink</v-icon>
                                        Switch Network
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="sendCancelLocation()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-crosshairs-off</v-icon>
                                        Send Cancel Location
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="showSimcardDialog(selected[0])">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-pencil</v-icon>
                                        Edit Simcard
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-list v-else-if="selected.length > 1" dense>
                        <v-list-item-group color="primary">
                            <v-list-item @click="gotoNetworkSwitch()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-satellite-uplink</v-icon>
                                        Switch Network
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="sendCancelLocation()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-crosshairs-off</v-icon>
                                        Send Cancel Location
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="showSimcardDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-pencil</v-icon>
                                        Bulk Edit Simcards
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                <v-btn text @click="header_filters = !header_filters" :disabled="!!search_global">
                    <v-icon left>mdi-filter</v-icon>Filters
                </v-btn>
                <v-dialog v-model="dialog_columns" scrollable max-width="350px" @input="(v) => v || dirtyStorage()">
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on"> <v-icon left>mdi-view-column</v-icon>Columns </v-btn>
                    </template>
                    <v-card flat>
                        <v-card-title>Show & Hide Columns</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-switch
                                v-for="column in selectableColumns"
                                :key="column.value"
                                v-model="column.selected"
                                color="primary"
                                :label="column.text"
                                hide-details
                            ></v-switch>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary darken-1"
                                text
                                @click.native="
                                    dialog_columns = false
                                    dirtyStorage()
                                "
                                >Done</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar-items>

            <template v-if="$vuetify.breakpoint.smAndUp">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            :color="show_retired ? 'white' : 'grey darken-2'"
                            @click="toggleRetired()"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-file-cabinet</v-icon>
                        </v-btn>
                    </template>
                    <span>Show non-billed retired simcards</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="resetSearch()" v-bind="attrs" v-on="on">
                            <v-icon>mdi-delete-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Reset filters</span>
                </v-tooltip>
            </template>
        </v-toolbar>

        <v-data-table
            v-model="selected"
            :headers="filteredHeaders"
            :items="filteredItems"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500] }"
            :loading="pending"
            no-results-text="Loading data..."
            class="elevation-1 mytable"
            :mobile-breakpoint="0"
            show-select
            @click:row="clickSelect"
        >
            <template v-slot:top>
                <v-expand-transition>
                    <div v-show="header_filters" class="elevation-3">
                        <v-toolbar v-for="(search, index) in filteredSearch" :key="index" dense flat>
                            <v-select
                                :items="selectableColumns"
                                v-model="filteredSearch[index].column"
                                hide-details
                                class="mr-4"
                                style="max-width: 220px"
                            ></v-select>
                            <v-text-field
                                v-model="filteredSearch[index].value"
                                hide-details
                                v-on:input="debounceFilter(filteredSearch[index].value)"
                                single-line
                                clearable
                            ></v-text-field>

                            <v-btn v-if="index == 0" icon color="primary" @click="addFilter()">
                                <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                            <v-btn v-else icon color="red" @click="removeFilter(index)">
                                <v-icon>mdi-minus-circle</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </div>
                </v-expand-transition>
            </template>

            <template v-slot:[`item.operator`]="{ item }">
                <img :src="getImgUrl(item.operator_sid)" class="pr-3" style="width: 72px; max-height: 24px" />
            </template>
            <template v-slot:[`item.voice_usage`]="{ item }">
                {{ item.voice_usage | do_math([':']) }}
            </template>
            <template v-slot:[`item.bundle`]="{ item }">
                <v-chip small outlined> <v-icon left>mdi-view-dashboard-outline</v-icon>{{ item.bundle }} </v-chip>
            </template>
            <template v-slot:[`item.start_date`]="{ item }">
                {{ item.start_date | date }}
            </template>
            <template v-slot:[`item.end_date`]="{ item }">
                {{ item.end_date | date }}
            </template>
            <template v-slot:[`item.termination_date`]="{ item }">
                {{ item.termination_date | date }}
            </template>
            <template v-slot:[`item.termination_date_billing`]="{ item }">
                {{ item.termination_date_billing | date }}
            </template>
            <template v-slot:[`item.session_start`]="{ item }">
                {{ item.session_start | datetime }}
            </template>
            <template v-slot:[`item.session_stop`]="{ item }">
                {{ item.session_stop | datetime }}
            </template>
            <template v-slot:[`item.last_update`]="{ item }">
                {{ item.last_update | datetime }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <template v-if="item.status === 2 && !item.start_date">
                    <v-chip color="orange" small dark> <v-icon left>mdi-sim</v-icon>Test Ready </v-chip>
                </template>
                <template v-else-if="item.status === 2">
                    <v-chip color="green" small dark> <v-icon left>mdi-sim</v-icon>Activated </v-chip>
                </template>
                <template v-else-if="item.status === 1">
                    <v-chip color="red" small dark> <v-icon left>mdi-sim-alert</v-icon>Deactivated </v-chip>
                </template>
                <template v-else-if="item.status === 0">
                    <v-chip color="gray" small dark> <v-icon left>mdi-sim-off</v-icon>Retired </v-chip>
                </template>
            </template>

            <v-alert slot="no-data" :value="true" color="info" icon="mdi-magnify-close" class="mt-4" dark>
                There are no results that match your search.
            </v-alert>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { generateFilter } from '../helpers'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        ...mapState('session', {
            stored(state) {
                return (
                    state.storage.simcards || {
                        headers: [],
                        options: {},
                        search_global: '',
                        search_fields: [],
                        show_retired: false,
                    }
                )
            },
            advanced_view: (state) => state.rules.advanced_view || false,
        }),
        filteredHeaders() {
            var selected_headers = this.headers.filter((h) => h.selected)
            var selected_values = selected_headers.map((x) => x.value)

            if (selected_values.length > 0) {
                this.$store.dispatch('session/storage', { name: 'simcards', headers: selected_values })
            }

            return selected_headers
        },
        filteredItems() {
            return this.payload.map((item) => {
                return Object.assign({}, item)
            })
        },
        searchColumns() {
            var selected_headers = this.headers.filter((h) => h.selected && h.searchable != false)
            var selected_values = selected_headers.map((x) => x.value)

            return selected_headers
        },
        selectableColumns() {
            if (this.advanced_view) {
                return this.headers.filter((h) => h.toggleable != false)
            } else {
                return this.headers.filter((h) => h.toggleable != false && h.advanced != true)
            }
        },
        filteredSearch() {
            if (this.advanced_view) {
                return this.search_fields
            } else {
                return this.search_fields.filter((s) =>
                    this.headers.some((h) => h.value == s.column && h.advanced != true)
                )
            }
        },
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
    },
    data() {
        return {
            headers: [
                { text: 'ICCID', value: 'iccid', selected: true },
                { text: 'IMSI', value: 'imsi', selected: false, width: '300px' },
                { text: 'Data Number', value: 'data_number', selected: true },
                { text: 'IP Address', value: 'ip_address', selected: false },
                { text: 'Bundle', value: 'bundle', selected: true },
                { text: 'Operator', value: 'operator', selected: true },
                { text: 'Start Date', value: 'start_date', selected: false },
                { text: 'End Date', value: 'end_date', selected: false },
                { text: 'Termination Date', value: 'termination_date', selected: false },
                { text: 'Termination Date Billing', value: 'termination_date_billing', selected: false },
                { text: 'Data Usage (MB)', value: 'data_usage', selected: true },
                { text: 'SMS Usage', value: 'sms_usage', selected: true },
                { text: 'Voice Usage (min)', value: 'voice_usage', selected: true },
                { text: 'Session Usage', value: 'session_usage', selected: false },
                { text: 'Session Start', value: 'session_start', selected: false },
                { text: 'Session Stop', value: 'session_stop', selected: false },
                { text: 'Last Update', value: 'last_update', selected: false },
                { text: 'Status', value: 'status', selected: true },
                { text: 'APN', value: 'apn', selected: false },
                { text: 'Hardware', value: 'hardware', selected: false },
                { text: 'Customer ID', value: 'customer_id', selected: false, advanced: true },
                { text: 'Customer Name', value: 'customer_name', selected: false, advanced: true },
                { text: 'Custom 1', value: 'custom_1', selected: false },
                { text: 'Custom 2', value: 'custom_2', selected: false },
                { text: 'Custom 3', value: 'custom_3', selected: false },
                { text: 'Custom 4', value: 'custom_4', selected: false },
                { text: 'Custom 5', value: 'custom_5', selected: false },
                { text: 'Customer Custom 1', value: 'customer_custom_1', selected: false },
                { text: 'Customer Custom 2', value: 'customer_custom_2', selected: false },
                { text: 'Customer Custom 3', value: 'customer_custom_3', selected: false },
                { text: 'Customer Custom 4', value: 'customer_custom_4', selected: false },
                { text: 'Customer Custom 5', value: 'customer_custom_5', selected: false },
            ],
            selected: [],
            search_global: '',
            search_fields: [{ column: 'iccid', value: '' }],
            dialog_columns: false,
            dialog_simcard: false,
            dialog_help: false,
            header_filters: false,
            date_picker: false,
            options: {},
            total: -1,
            editedItem: {},
            snackbar: false,
            snackbar_message: '',
            show_retired: false,
        }
    },
    methods: {
        getDataFromApi(reset_total = false, total_only = false) {
            this.selected = []

            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            if (!!this.search_global) {
                const search_fields = this.headers
                    .filter((h) => h.selected)
                    .map((h) => {
                        h = { column: h.value, value: this.search_global }
                        return h
                    })
                var api_filter = this.createApiFilter(search_fields, true)
            } else {
                var api_filter = this.createApiFilter(this.search_fields)
            }

            this.$store
                .dispatch('api/call', {
                    name: total_only ? 'simcards_total' : 'default',
                    url: '/simcards',
                    params: {
                        count: itemsPerPage,
                        skip: (page - 1) * itemsPerPage,
                        sort_by: sortBy[0],
                        descending: sortDesc[0],
                        show_retired: this.show_retired,
                        filter: api_filter,
                        total: total_only ? 'only' : '',
                    },
                })
                .then((response) => {
                    if (response.data.total) {
                        this.total = response.data.total
                    } else {
                        // Store in local storage
                        this.$store.dispatch('session/storage', {
                            name: 'simcards',
                            options: this.options,
                            search_global: this.search_global,
                            search_fields: this.search_fields,
                            show_retired: this.show_retired,
                        })

                        if (!total_only) {
                            this.dirtyStorage()
                        }

                        if (this.total == -1 || reset_total) {
                            // Fetch only the items total
                            this.getDataFromApi(false, true)
                        }
                    }
                })
        },
        createApiFilter(filter, or_clause = false) {
            const conversions = {
                operator: { kpn: 'KPOM', telenor: 'TPOM', tele2: '2POM', 't-mobile': 'TMPOM' },
                status: { activated: '2', deactivated: '1', retired: '0' },
                voice_usage: {
                    _method: function (value) {
                        const matches = value.match(/[0-9-.]+/gi)
                        if (matches) {
                            for (let match of matches) {
                                return value.replace(match, match * 60)
                            }
                        }
                    },
                },
            }

            return generateFilter(filter, or_clause, conversions)
        },
        showColumn(col) {
            return this.headers.find((h) => h.value === col).selected
        },
        showSimcardDialog() {
            if (this.selected.length > 0) {
                if (this.selected.length == 1) {
                    this.editedItem = Object.assign({}, this.selected[0])
                }
                this.dialog_simcard = true
            }
        },
        clickSelect(item) {
            const index = this.selected.findIndex((v) => v.id === item.id)
            if (index !== -1) {
                this.selected.splice(index, 1)
            } else {
                this.selected.push(item)
            }
        },
        gotoScan(iccid) {
            this.$router.push({ path: `/diagnostics/scan/${iccid}` })
        },
        gotoNetworkSwitch() {
            let iccids = _map(this.selected, 'iccid')
            this.$router.push({ path: `/switch/${iccids}` })
        },
        sendCancelLocation() {
            let iccids = _map(this.selected, 'iccid')
            this.$store
                .dispatch('api/call', {
                    name: 'cancel_location',
                    method: 'post',
                    url: '/actions/cancel_location',
                    data: {
                        iccids: iccids,
                    },
                })
                .then(
                    (response) => {
                        this.snackbar = true
                        this.snackbar_message = 'Cancel location has successfully been send.'
                    },
                    (error) => {}
                )
        },
        resetFromStorage() {
            if ('headers' in this.stored) {
                if (this.stored.headers.length) {
                    for (let header of this.headers) {
                        header.selected = this.stored.headers.includes(header.value) ? true : false
                    }
                }
            }
            if ('options' in this.stored) {
                if ('sortBy' in this.stored.options) {
                    this.options = this.stored.options
                } else {
                    this.options = { ...this.stored.options, ...{ sortBy: ['status'], sortDesc: ['true'] } }
                }
            }
            if ('search_global' in this.stored) {
                this.search_global = this.stored['search_global']
            }
            if ('search_fields' in this.stored) {
                if (this.stored.search_fields.length) {
                    this.search_fields = this.stored['search_fields']
                    for (let field of this.search_fields) {
                        if (field['value']) {
                            this.header_filters = true
                        }
                    }
                }
            }
            if ('show_retired' in this.stored) {
                this.show_retired = this.stored['show_retired']
            }
        },
        dirtyStorage() {
            this.$store.dispatch('api/call', {
                name: 'dirty_storage',
                method: 'put',
                url: '/account/storage',
                data: {
                    storage: { simcards: this.stored },
                },
            })
        },
        toggleRetired() {
            this.show_retired = !this.show_retired
            this.getDataFromApi(true)
        },
        resetSearch() {
            for (let field in this.search_fields) {
                if (this.search_fields.hasOwnProperty(field)) {
                    this.search_fields[field] = ''
                }
            }
            this.search_global = ''
            this.search_fields = [{ column: 'iccid', value: '' }]

            this.getDataFromApi(true)
            this.dirtyStorage()
        },
        addFilter() {
            //this.$set(this.filter, field, value)
            this.search_fields.push({ column: 'iccid', value: '' })
        },
        removeFilter(index) {
            this.search_fields.splice(index, 1)
            this.getDataFromApi(true)
        },
        getImgUrl(img) {
            const operators = { kpn_rd: 'kpn', kpn_eh: 'kpn', telenor: 'telenor', tele2: 'tele2', t_mobile: 't-mobile', arkessa: 'arkessa', vodafone: 'vodafone', conexa_ld: 'conexa', conexa_os: 'conexa' }
            return '/img/' + operators[img] + '.svg'
        },
        close() {
            this.dialog_simcard = false
            setTimeout(() => {
                this.editedItem = {}
            }, 300)
        },
        save() {
            if (this.selected.length > 0) {
                const data_keys = ['end_date', 'status', 'custom_1', 'custom_2', 'custom_3', 'custom_4', 'custom_5']

                let data = { ids: _map(this.selected, 'id') }

                for (let select of this.selected) {
                    let index = _findIndex(this.filteredItems, ['id', select.id])

                    for (const key of data_keys) {
                        if (this.payload[index][key] != this.editedItem[key]) {
                            data[key] = this.editedItem[key]
                        }
                    }
                }

                this.$store
                    .dispatch('api/call', {
                        name: 'simcard_edit',
                        method: 'put',
                        url: '/simcards',
                        data: data,
                    })
                    .then((response) => {
                        for (let select of this.selected) {
                            let index = _findIndex(this.filteredItems, ['id', select.id])
                            Object.assign(this.payload[index], this.editedItem)
                        }
                        this.selected = []
                    })

                this.close()
            }
        },
        debounceFilter: _debounce(function (value) {
            if (value) {
                const operators = value.match(/(!=|>=|<=|!\(|\(|\)|\[|\]|\*|!|=|>|<)/g)

                if (operators) {
                    if (operators.includes('(') | operators.includes('!(') | operators.includes('[')) {
                        if (operators.includes(')') | operators.includes(']')) {
                            this.getDataFromApi(true)
                        }
                    } else if (value.length > operators[0].length) {
                        this.getDataFromApi(true)
                    }
                } else {
                    this.getDataFromApi(true)
                }
            } else {
                this.getDataFromApi(true)
            }
        }, 800),
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    created() {
        this.resetFromStorage()

        if (typeof this.$route.query.sort_by !== 'undefined') {
            this.options.sortBy[0] = this.$route.query.sort_by
        }

        if (typeof this.$route.query.descending !== 'undefined') {
            this.options.sortDesc[0] = this.$route.query.descending
        }

        if (typeof this.$route.query.filter !== 'undefined') {
            this.search_global = ''
            this.search_fields = []
            this.header_filters = true
            let filters = this.$route.query.filter.split(',')
            for (let filter of filters) {
                let args = filter.split(':')
                this.search_fields.push({ column: args[0], value: args[1] })
            }
        }
    },
}
</script>
