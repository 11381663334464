<template>
    <div>
        <v-dialog dark v-model="dialog" max-width="1000" persistent>
            <v-card :color="prospectColor">
                <v-card-title>
                    <span class="headline" v-if="new_item">New Customer</span>
                    <span class="headline" v-else>Edit Customer</span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="new_item" icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-stepper v-if="new_item" v-model="stepper" vertical>
                        <v-stepper-step :rules="[() => step_1_valid]" :complete="stepper > 1" step="1">
                            Customer Details
                        </v-stepper-step>
                        <v-stepper-content step="1">
                            <v-card dark color="secondary" class="mb-6">
                                <v-card-text>
                                    <v-alert
                                        v-if="editedItem.status === 'prospect'"
                                        icon="mdi-alert"
                                        light
                                        border="top"
                                        type="warning"
                                        elevation="2"
                                    >
                                        You've set this customer as <strong>Prospect</strong>, keep in mind that this
                                        customer will not receive any invoices until the customer status is updated to <strong>Customer</strong>!
                                    </v-alert>
                                    <v-form ref="step_1">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.customer_name"
                                                    :rules="[rules.required]"
                                                    prepend-icon="mdi-briefcase-account"
                                                    label="Customer Name"
                                                    hint="Customer Name"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="editedItem.status"
                                                    :items="status"
                                                    item-text="label"
                                                    item-value="value"
                                                    prepend-icon="mdi-account-check"
                                                    label="Status"
                                                    hint="Customer status"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete
                                                    v-model="editedItem.account_manager"
                                                    :items="account_managers"
                                                    prepend-icon="mdi-account-tie"
                                                    label="Account Manager"
                                                    hint="The account manager for this customer"
                                                    dense
                                                    outlined
                                                    hide-details
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col v-if="editedItem.account_manager != 'WebOrders'" cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.salesforce_id"
                                                    :rules="[rules.sfid]"
                                                    prepend-icon="mdi-salesforce"
                                                    label="Salesforce ID"
                                                    hint="Salesforce ID"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-textarea
                                                    v-model="editedItem.support_mail"
                                                    :rules="[rules.required, rules.email_list]"
                                                    prepend-icon="mdi-email"
                                                    label="Support Notifications"
                                                    hint="This will be used to notify the customer about disruptions, maintenance, etc."
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validateStep(2)"> Continue </v-btn>
                        </v-stepper-content>
                        <v-stepper-step :rules="[() => step_2_valid]" :complete="stepper > 2" step="2">
                            Address Details
                        </v-stepper-step>
                        <v-stepper-content step="2">
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-text>
                                    <v-form ref="step_2">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.shipping_name"
                                                    prepend-icon="mdi-card-account-mail"
                                                    label="Recipient"
                                                    hint="Recipient Name"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.shipping_address"
                                                    prepend-icon="mdi-map-marker"
                                                    label="Address Line and number"
                                                    hint="The street and number"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.shipping_postal_code"
                                                    prepend-icon="mdi-mailbox"
                                                    label="Postal Code"
                                                    hint="Postal Code"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.shipping_city"
                                                    prepend-icon="mdi-city"
                                                    label="Town / city"
                                                    hint="The name of the town or city"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete
                                                    v-model="editedItem.shipping_country"
                                                    :items="country_list"
                                                    prepend-icon="mdi-earth"
                                                    hint="The name of the country"
                                                    :rules="[rules.required]"
                                                    item-text="text"
                                                    item-value="text"
                                                    label="Country"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validateStep(3)"> {{ submitButton }} </v-btn>
                            <v-btn text @click="stepper--"> Back </v-btn>
                        </v-stepper-content>
                        <v-stepper-step
                            v-if="editedItem.status !== 'prospect'"
                            :rules="[() => step_3_valid]"
                            :complete="stepper > 3"
                            step="3"
                        >
                            Invoice Details
                        </v-stepper-step>
                        <v-stepper-content v-if="editedItem.status !== 'prospect'" step="3">
                            <v-checkbox
                                class="ma-2"
                                v-model="sameAsAddress"
                                label="Same as Address Details"
                            ></v-checkbox>
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-text>
                                    <v-form ref="step_3">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.invoice_name"
                                                    label="Invoice Recipient"
                                                    hint="The name on the onvoice"
                                                    prepend-icon="mdi-card-account-mail"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.purchase_order_reference"
                                                    label="Invoice Reference"
                                                    hint="The reference on the invoice, e.g. the purchase order number"
                                                    prepend-icon="mdi-pound"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-textarea
                                                    v-model="editedItem.invoice_mail"
                                                    :rules="[rules.required, rules.email_list]"
                                                    prepend-icon="mdi-email"
                                                    label="E-Mail addresses for the invoice"
                                                    hint="These e-mail addresses will receive the invoice, email addresses should be separated by a comma."
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.invoice_address"
                                                    label="Address Line and number"
                                                    hint="The street and number"
                                                    prepend-icon="mdi-map-marker"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.invoice_postal_code"
                                                    prepend-icon="mdi-mailbox"
                                                    label="Postal Code"
                                                    hint="Postal Code"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.invoice_city"
                                                    label="Town / city"
                                                    hint="The address to send the invoice to"
                                                    prepend-icon="mdi-city"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete
                                                    v-model="editedItem.invoice_country"
                                                    :items="country_list"
                                                    prepend-icon="mdi-earth"
                                                    hint="The name of the country"
                                                    :rules="[rules.required]"
                                                    item-text="text"
                                                    item-value="text"
                                                    label="Country"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.iban"
                                                    prepend-icon="mdi-bank"
                                                    label="IBAN Code"
                                                    hint="Bank Account Number"
                                                    :rules="[rules.iban]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="editedItem.payment_method"
                                                    :items="payment_methods"
                                                    item-text="name"
                                                    return-object
                                                    prepend-icon="mdi-cash-check"
                                                    label="Payment Method"
                                                    hint="Select Payment Method"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="editedItem.payment_days"
                                                    :items="paymentTerms"
                                                    :rules="[rules.required]"
                                                    item-text="name"
                                                    return-object
                                                    prepend-icon="mdi-calendar-today"
                                                    label="Payment Terms"
                                                    hint="Select the Payment Terms"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="editedItem.vat_code"
                                                    :items="vat_code_items"
                                                    prepend-icon="mdi-cash-lock"
                                                    label="VAT Tariff"
                                                    hint="VAT Percentage"
                                                    item-text="name"
                                                    item-value="id"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <span> {{ item.name }} ({{ item.id }})</span>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-if="this.required_vat_code.includes(this.editedItem.vat_code)"
                                                    v-model="editedItem.vat_number"
                                                    prepend-icon="mdi-factory"
                                                    label="VAT Number"
                                                    hint="Company VAT Number"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :rules="[rules.vat, rules.required]"
                                                >
                                                </v-text-field>
                                                <v-text-field
                                                    v-if="!this.required_vat_code.includes(this.editedItem.vat_code)"
                                                    v-model="editedItem.vat_number"
                                                    prepend-icon="mdi-factory"
                                                    label="VAT Number"
                                                    hint="Company VAT Number"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :rules="[rules.vat]"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validateStep(4)"> Continue </v-btn>
                            <v-btn text @click="stepper--"> Back </v-btn>
                        </v-stepper-content>
                        <v-stepper-step
                            v-if="editedItem.status !== 'prospect'"
                            :rules="[() => step_4_valid]"
                            :complete="stepper > 4"
                            step="4"
                        >
                            Contact Details
                        </v-stepper-step>
                        <v-stepper-content v-if="editedItem.status !== 'prospect'" step="4">
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-text>
                                    <v-form ref="step_4">
                                        <v-row>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="editedItem.contact_person"
                                                    prepend-icon="mdi-account"
                                                    label="Contact Person"
                                                    hint="The customer contact person"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field
                                                    v-model="editedItem.phone_number"
                                                    prepend-icon="mdi-phone-in-talk"
                                                    label="Phone Number"
                                                    hint="The contact persons phone number"
                                                    :rules="[rules.phone]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-select
                                                    v-model="editedItem.support_24x7"
                                                    :items="support_24x7"
                                                    :rules="[rules.required]"
                                                    label="24/7 Support"
                                                    prepend-icon="mdi-alert"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-textarea
                                                    v-model="editedItem.bundle_trigger_mail"
                                                    prepend-icon="mdi-email"
                                                    label="Bundle Trigger Notifications"
                                                    hint="This will be used to send bundle trigger emails, multiple recipients separated by comma"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    :rules="[rules.email_list]"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validateStep(5)"> Continue </v-btn>
                            <v-btn text @click="stepper--"> Back </v-btn>
                        </v-stepper-content>
                        <v-stepper-step
                            v-if="editedItem.status !== 'prospect'"
                            :rules="[() => step_5_valid]"
                            :complete="stepper > 5"
                            step="5"
                        >
                            Test Ready Periods
                        </v-stepper-step>
                        <v-stepper-content v-if="editedItem.status !== 'prospect'" step="5">
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-text>
                                    <v-form ref="step_5">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.test_ready_period_telenor"
                                                    :rules="[rules.required]"
                                                    prepend-icon="mdi-timer-sand"
                                                    label="Test Ready Period Telenor"
                                                    hint="The test ready period for Telenor"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.test_ready_period_kpn"
                                                    :rules="[rules.required]"
                                                    prepend-icon="mdi-timer-sand"
                                                    label="Test Ready Period KPN"
                                                    hint="The test ready period for KPN"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.test_ready_period_tele2"
                                                    :rules="[rules.required]"
                                                    prepend-icon="mdi-timer-sand"
                                                    label="Test Ready Period Tele2"
                                                    hint="The test ready period for Tele2"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.test_ready_period_tmobile"
                                                    :rules="[rules.required]"
                                                    prepend-icon="mdi-timer-sand"
                                                    label="Test Ready Period T-Mobile"
                                                    hint="The test ready period for T-Mobile"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.test_ready_period_vodafone"
                                                    :rules="[rules.required]"
                                                    prepend-icon="mdi-timer-sand"
                                                    label="Test Ready Period Vodafone"
                                                    hint="The test ready period for Vodafone"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.test_ready_period_conexa_ld"
                                                    :rules="[rules.required]"
                                                    prepend-icon="mdi-timer-sand"
                                                    label="Test Ready Period Conexa London"
                                                    hint="The test ready period for Conexa London"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.test_ready_period_conexa_os"
                                                    :rules="[rules.required]"
                                                    prepend-icon="mdi-timer-sand"
                                                    label="Test Ready Period Conexa Oslo"
                                                    hint="The test ready period for Conexa Oslo"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validateStep(6)"> Continue </v-btn>
                            <v-btn text @click="stepper--"> Back </v-btn>
                        </v-stepper-content>
                        <v-stepper-step
                            v-if="editedItem.status !== 'prospect'"
                            :rules="[() => step_6_valid]"
                            :complete="stepper > 6"
                            step="6"
                        >
                            Contract Details
                        </v-stepper-step>
                        <v-stepper-content v-if="editedItem.status !== 'prospect'" step="6">
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-text>
                                    <v-form ref="step_6">
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-text-field
                                                    v-model="editedItem.shipping_fee"
                                                    :rules="[rules.required, rules.number]"
                                                    prepend-icon="mdi-package-variant"
                                                    label="Shipping Fee"
                                                    hint="The price per order."
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    prefix="€"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.contract_term_telenor"
                                                    :rules="[rules.required, rules.number]"
                                                    prepend-icon="mdi-file-document-outline"
                                                    label="Contract term Telenor"
                                                    hint="The contract term for Telenor"
                                                    value="180"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.contract_term_kpn"
                                                    :rules="[rules.required, rules.number]"
                                                    prepend-icon="mdi-file-document-outline"
                                                    label="Contract term KPN"
                                                    hint="The contract term for KPN"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.contract_term_tele2"
                                                    :rules="[rules.required, rules.number]"
                                                    prepend-icon="mdi-file-document-outline"
                                                    label="Contract term Tele2"
                                                    hint="The contract term for Tele2"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.contract_term_tmobile"
                                                    :rules="[rules.required, rules.number]"
                                                    prepend-icon="mdi-file-document-outline"
                                                    label="Contract term T-mobile"
                                                    hint="The contract term for T-mobile"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.contract_term_vodafone"
                                                    :rules="[rules.required, rules.number]"
                                                    prepend-icon="mdi-file-document-outline"
                                                    label="Contract term Vodafone"
                                                    hint="The contract term for Vodafone"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.contract_term_conexa_ld"
                                                    :rules="[rules.required, rules.number]"
                                                    prepend-icon="mdi-file-document-outline"
                                                    label="Contract term Conexa London"
                                                    hint="The contract term for Conexa London"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.contract_term_conexa_os"
                                                    :rules="[rules.required, rules.number]"
                                                    prepend-icon="mdi-file-document-outline"
                                                    label="Contract term Conexa Oslo"
                                                    hint="The contract term for Conexa Oslo"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validateStep(7)"> Continue </v-btn>
                            <v-btn text @click="stepper--"> Back </v-btn>
                        </v-stepper-content>
                    </v-stepper>
                    <v-container v-else grid-list-md>
                        <v-layout wrap>
                            <v-alert
                                v-if="editedItem.specials && editedItem.specials.length > 0"
                                icon="mdi-alert"
                                dense
                                border="top"
                                type="warning"
                            >
                            <div v-for="(special, index) in editedItem.specials" :key="index">
                                <div v-for="(line, i) in ensureArray(special.specials)" :key="i">
                                    <ul>
                                        <li>
                                            {{ line }}
                                        </li>
                                    </ul>
                            </div>
                            </div>
                            </v-alert>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="editedItem.customer_name"
                                    :rules="[rules.required]"
                                    prepend-icon="mdi-briefcase-account"
                                    label="Customer Name"
                                    hint="Customer Name"
                                ></v-text-field>
                                <v-select
                                    v-model="editedItem.status"
                                    :items="status"
                                    prepend-icon="mdi-account-check"
                                    label="Status"
                                    hint="Customer Status"
                                    item-text="label"
                                    item-value="value"
                                ></v-select>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="editedItem.salesforce_id"
                                    :rules="[rules.sfid]"
                                    prepend-icon="mdi-salesforce"
                                    label="Salesforce ID"
                                    hint="Salesforce ID"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete
                                    v-model="editedItem.account_manager"
                                    :items="account_managers"
                                    prepend-icon="mdi-account-tie"
                                    label="Account Manager"
                                    hint="The account manager for this specific customer"
                                ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea
                                    v-model="editedItem.support_mail"
                                    :rules="[rules.required, rules.email_list]"
                                    prepend-icon="mdi-email"
                                    label="Support Notifications"
                                    hint="This will be used to notify the customer about disruptions, maintenance, etc."
                                    class="mb-4"
                                ></v-textarea>
                            </v-flex>
                            <v-form ref="edit_customer">
                                <v-expansion-panels focusable>
                                    <v-expansion-panel>
                                    <v-expansion-panel-header disable-icon-rotate>
                                        Exceptions
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content eager>
                                        <v-flex>
                                        <div>
                                            <div 
                                                v-if="editedItem.specials.length"
                                                class="mb-2"
                                            >
                                                <v-textarea
                                                    v-for="(category, catIndex) in ensureArray(editedItem.specials)" 
                                                    :key="catIndex"
                                                    v-model="category.specialsString"
                                                    prepend-icon="mdi-account-alert"
                                                    label="Exceptions"
                                                    hint="Enter special terms, one per line"
                                                    dense
                                                    outlined
                                                ></v-textarea>
                                            </div>
                                            <div v-else>
                                                <v-textarea
                                                    v-model="editedItem.specials.specialsString"
                                                    prepend-icon="mdi-account-alert"
                                                    label="Exceptions"
                                                    hint="Enter special terms, one per line"
                                                    dense
                                                    outlined
                                                ></v-textarea>
                                            </div>
                                        </div>
                                        </v-flex>
                                    </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            Address Details
                                            <template v-slot:actions>
                                                <v-icon :color="addressIconState">{{ addressDetailsState }}</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.shipping_name"
                                                    prepend-icon="mdi-card-account-mail"
                                                    label="Recipient"
                                                    hint="Recipient Name"
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.shipping_address"
                                                    prepend-icon="mdi-map-marker"
                                                    label="Address Line and number"
                                                    hint="The street and number"
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.shipping_postal_code"
                                                    prepend-icon="mdi-mailbox"
                                                    label="Postal Code"
                                                    hint="Postal Code"
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.shipping_city"
                                                    prepend-icon="mdi-city"
                                                    label="Town / city"
                                                    hint="The name of the town or city"
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-autocomplete
                                                    v-model="editedItem.shipping_country"
                                                    :items="country_list"
                                                    prepend-icon="mdi-earth"
                                                    hint="The name of the country"
                                                    :rules="[rules.required]"
                                                    item-text="text"
                                                    item-value="text"
                                                    label="Country"
                                                ></v-autocomplete>
                                            </v-flex>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate >
                                            Invoice Details
                                            <template v-slot:actions>
                                                <v-icon :color="invoiceIconState">{{ invoiceDetailsState }}</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.invoice_name"
                                                    label="Invoice Recipient"
                                                    hint="The name on the onvoice"
                                                    prepend-icon="mdi-card-account-mail"
                                                    :rules="[rules.invoiceRequired]"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.purchase_order_reference"
                                                    label="Invoice Reference"
                                                    hint="The reference on the invoice, eg. the purchase order number."
                                                    prepend-icon="mdi-pound"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                
                                                <v-textarea
                                                    v-model="editedItem.invoice_mail"
                                                    :rules="[rules.invoiceRequired, rules.email_list]"
                                                    prepend-icon="mdi-email"
                                                    label="E-Mail addresses for the invoice"
                                                    hint="These e-mail addresses will receive the invoice, email addresses should be separated by a comma."
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                ></v-textarea>
                                            
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.invoice_address"
                                                    label="Address Line and number"
                                                    hint="The street and number"
                                                    prepend-icon="mdi-map-marker"
                                                    :rules="[rules.invoiceRequired]"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.invoice_postal_code"
                                                    prepend-icon="mdi-mailbox"
                                                    label="Postal Code"
                                                    hint="Postal Code"
                                                    :rules="[rules.invoiceRequired]"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.invoice_city"
                                                    label="Town / city"
                                                    hint="The address to send the invoice to"
                                                    prepend-icon="mdi-city"
                                                    :rules="[rules.invoiceRequired]"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-autocomplete
                                                    v-model="editedItem.invoice_country"
                                                    :items="country_list"
                                                    prepend-icon="mdi-earth"
                                                    hint="The name of the country"
                                                    :rules="[rules.invoiceRequired]"
                                                    item-text="text"
                                                    item-value="text"
                                                    label="Country"
                                                ></v-autocomplete>
                                            </v-flex>
                                            <v-flex>
                                                <v-select
                                                    v-model="editedItem.payment_method"
                                                    :items="payment_methods"
                                                    item-text="name"
                                                    return-object
                                                    prepend-icon="mdi-cash-check"
                                                    label="Payment Method"
                                                    hint="Select Payment Method"
                                                    :rules="[rules.invoiceRequired]"
                                                ></v-select>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.iban"
                                                    prepend-icon="mdi-bank"
                                                    label="IBAN Code"
                                                    hint="Bank Account Number"
                                                    :rules="[rules.iban, rules.ibanRequired]"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-select
                                                    v-model="editedItem.payment_days"
                                                    :items="paymentTerms"
                                                    :rules="[rules.invoiceRequired]"
                                                    item-text="name"
                                                    return-object
                                                    prepend-icon="mdi-calendar-today"
                                                    label="Payment Terms"
                                                    hint="Select the Payment Terms"
                                                ></v-select>
                                            </v-flex>
                                            <v-flex>
                                                <v-select
                                                    v-model="editedItem.vat_code"
                                                    :items="vat_code_items"
                                                    prepend-icon="mdi-cash-lock"
                                                    label="VAT Tariff"
                                                    hint="VAT Percentage"
                                                    item-text="name"
                                                    item-value="id"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <span> {{ item.name }} ({{ item.id }})</span>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-if="this.required_vat_code.includes(this.editedItem.vat_code)"
                                                    v-model="editedItem.vat_number"
                                                    prepend-icon="mdi-factory"
                                                    label="VAT Number"
                                                    hint="Company VAT Number"
                                                    :rules="[rules.vat, rules.invoiceRequired]"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-if="!this.required_vat_code.includes(this.editedItem.vat_code)"
                                                    v-model="editedItem.vat_number"
                                                    prepend-icon="mdi-factory"
                                                    label="VAT Number"
                                                    hint="Company VAT Number"
                                                    :rules="[rules.vatRequired, rules.vat]"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            Contact Details
                                            <template v-slot:actions>
                                                <v-icon>mdi-account-details</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.contact_person"
                                                    prepend-icon="mdi-account"
                                                    label="Contact Person"
                                                    hint="The customer contact person"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.phone_number"
                                                    prepend-icon="mdi-phone-in-talk"
                                                    label="Phone Number"
                                                    hint="The contact persons phone number"
                                                    :rules="[rules.phone]"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-text-field
                                                    v-model="editedItem.support_24x7"
                                                    prepend-icon="mdi-alert"
                                                    label="Premium Code"
                                                    hint="Premium SLA code"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex>
                                                <v-textarea
                                                    v-model="editedItem.bundle_trigger_mail"
                                                    prepend-icon="mdi-email"
                                                    label="Bundle Trigger Notifications"
                                                    hint="This will be used to send bundle trigger emails, multiple recipients separated by comma"
                                                    class="mb-4"
                                                    :rules="[rules.email_list]"
                                                ></v-textarea>
                                            </v-flex>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            Test Ready Periods
                                            <template v-slot:actions>
                                                <v-icon>mdi-timer-sand</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-row>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model="editedItem.test_ready_period_telenor"
                                                        :rules="[rules.required]"
                                                        prepend-icon="mdi-timer-sand"
                                                        label="Test Ready Period Telenor"
                                                        hint="The test ready period for Telenor"
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model="editedItem.test_ready_period_kpn"
                                                        :rules="[rules.required]"
                                                        prepend-icon="mdi-timer-sand"
                                                        label="Test Ready Period KPN"
                                                        hint="The test ready period for KPN"
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model="editedItem.test_ready_period_tele2"
                                                        :rules="[rules.required]"
                                                        prepend-icon="mdi-timer-sand"
                                                        label="Test Ready Period Tele2"
                                                        hint="The test ready period for Tele2"
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model="editedItem.test_ready_period_tmobile"
                                                        :rules="[rules.required]"
                                                        prepend-icon="mdi-timer-sand"
                                                        label="Test Ready Period T-Mobile"
                                                        hint="The test ready period for T-Mobile"
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model="editedItem.test_ready_period_vodafone"
                                                        :rules="[rules.required]"
                                                        prepend-icon="mdi-timer-sand"
                                                        label="Test Ready Period Vodafone"
                                                        hint="The test ready period for Vodafone"
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model="editedItem.test_ready_period_conexa_ld"
                                                        :rules="[rules.required]"
                                                        prepend-icon="mdi-timer-sand"
                                                        label="Test Ready Period Conexa London"
                                                        hint="The test ready period for Conexa London"
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model="editedItem.test_ready_period_conexa_os"
                                                        :rules="[rules.required]"
                                                        prepend-icon="mdi-timer-sand"
                                                        label="Test Ready Period Conexa Oslo"
                                                        hint="The test ready period for Conexa Oslo"
                                                        class="mb-4"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            Contract Details
                                            <template v-slot:actions>
                                                <v-icon>mdi-file-document-outline</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <v-row>
                                                <v-col cols="12" sm="12" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model="editedItem.shipping_fee"
                                                        :rules="[rules.required, rules.number]"
                                                        prepend-icon="mdi-package-variant"
                                                        label="Shipping Fee"
                                                        hint="The price per order."
                                                        prefix="€"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model.number="editedItem.contract_term_telenor"
                                                        :rules="[rules.number_required, rules.number]"
                                                        prepend-icon="mdi-file-document-outline"
                                                        label="Contract term Telenor"
                                                        hint="The contract term for Telenor"
                                                        type="number"
                                                        hide-spin-buttons
                                                        class="mb-4"
                                                        value="180"
                                                        @wheel="$event.target.blur()"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model.number="editedItem.contract_term_kpn"
                                                        :rules="[rules.number_required, rules.number]"
                                                        prepend-icon="mdi-file-document-outline"
                                                        label="Contract term KPN"
                                                        hint="The contract term for KPN"
                                                        type="number"
                                                        hide-spin-buttons
                                                        class="mb-4"
                                                        @wheel="$event.target.blur()"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model.number="editedItem.contract_term_tele2"
                                                        :rules="[rules.number_required, rules.number]"
                                                        prepend-icon="mdi-file-document-outline"
                                                        label="Contract term Tele2"
                                                        type="number"
                                                        hide-spin-buttons
                                                        class="mb-4"
                                                        value="180"
                                                        @wheel="$event.target.blur()"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model.number="editedItem.contract_term_tmobile"
                                                        :rules="[rules.number_required, rules.number]"
                                                        prepend-icon="mdi-file-document-outline"
                                                        label="Contract term T-mobile"
                                                        type="number"
                                                        hide-spin-buttons
                                                        class="mb-4"
                                                        value="180"
                                                        @wheel="$event.target.blur()"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model.number="editedItem.contract_term_vodafone"
                                                        :rules="[rules.number_required, rules.number]"
                                                        prepend-icon="mdi-file-document-outline"
                                                        label="Contract term Vodafone"
                                                        type="number"
                                                        hide-spin-buttons
                                                        class="mb-4"
                                                        value="180"
                                                        @wheel="$event.target.blur()"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model.number="editedItem.contract_term_conexa_ld"
                                                        :rules="[rules.number_required, rules.number]"
                                                        prepend-icon="mdi-file-document-outline"
                                                        label="Contract term Conexa London"
                                                        type="number"
                                                        hide-spin-buttons
                                                        class="mb-4"
                                                        value="180"
                                                        @wheel="$event.target.blur()"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" v-if="new_item | (selected.length == 1)">
                                                    <v-text-field
                                                        v-model.number="editedItem.contract_term_conexa_os"
                                                        :rules="[rules.number_required, rules.number]"
                                                        prepend-icon="mdi-file-document-outline"
                                                        label="Contract term Conexa Oslo"
                                                        type="number"
                                                        hide-spin-buttons
                                                        class="mb-4"
                                                        value="180"
                                                        @wheel="$event.target.blur()"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-form>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions v-if="!new_item">
                    <v-spacer></v-spacer>
                    <v-btn :disabled="saveButtonState" text @click.native="close">Close</v-btn>
                    <v-btn :disabled="saveButtonState" text @click.native="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="err_dialog" max-width="800">
            <v-card>
                <v-toolbar color="warning" dark>
                    <v-card-title>Customer name overlaps!</v-card-title>
                </v-toolbar>
                <v-card-text>
                    The customer name you've entered: {{ this.editedItem.customer_name }}, overlaps with existing
                    customers. If you're very sure the customer name is correct you can use the Force Creation button.
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click=";(err_dialog = false), (dialog = false)">Close</v-btn>
                    <v-btn color="warning" @click="save((force = true))">Force Creation</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <h1 class="mb-4">Customers</h1>

        <v-toolbar dense dark>
            <v-select
                v-model="selected_header"
                width="300"
                prepend-icon="mdi-account-search"
                :items="headers"
                item-text="text"
                item-value="value"
                hide-details
                style="max-width: 150px"
            ></v-select>
            <v-text-field
                v-if="selected_header !== 'status' && selected_header !== 'account_manager'"
                name="searchfield"
                v-model="search"
                clearable
                hide-details
            >
            </v-text-field>
            <v-select
                v-if="selected_header === 'status'"
                name="searchfield"
                clearable
                v-model="search"
                hide-details
                :items="['Prospect', 'Customer', 'Lost']"
            ></v-select>
            <v-select
                v-if="selected_header === 'account_manager'"
                name="searchfield"
                clearable
                v-model="search"
                hide-details
                :items="account_managers"
            ></v-select>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn text @click="showEditDialog(true)"> <v-icon left>mdi-account-plus</v-icon> New Customer </v-btn>
                <v-menu offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on" :disabled="toggleActionsButton">
                            <v-icon left>mdi-ballot-outline</v-icon>
                            Actions
                        </v-btn>
                    </template>
                    <v-list v-if="selected.length >= 1" dense>
                        <v-list-item-group color="primary">
                            <v-list-item @click="showEditDialog()" :disabled="selected.length > 1">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-account-edit</v-icon>
                                        Edit Customer
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content @click="exportSelected(selected)">
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-file-excel-box</v-icon>
                                        Export Selected
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            :loading="pending"
            :headers="headers"
            :items="filteredItems"
            :single-select="false"
            show-select
            :mobile-breakpoint="0"
            :footer-props="{ itemsPerPageOptions: [50, 100, 500, 1000, 2000] }"
            no-results-text="Loading data..."
            class="elevation-1"
            item-key="id"
            sort-by="id"
            sort-desc
            @click:row="clickSelect"
        >
            <template v-slot:[`item.status`]="{ item }">
                <v-chip small :color="customerStatus(item.status).color" text-color="white">
                    <v-avatar left>
                        <v-icon small>{{ customerStatus(item.status).icon }}</v-icon>
                    </v-avatar>
                    {{ customerStatus(item.status).label }}
                </v-chip>
            </template>
            <template v-slot:[`item.created`]="{ item }">
                {{ item.created | datetime }}
            </template>
            <template v-slot:[`item.updated`]="{ item }">
                {{ item.updated | datetime }}
            </template>
        </v-data-table>
        <v-snackbar
            centered
            top
            :color="snackbar.color"
            v-model="snackbar.state"
            :timeout="snackbar.timeout"
            :multi-line="snackbar.multiLine"
        >
            {{ snackbar.message }}
            <template v-if="snackbar.persistent" v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbar.state = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { checkVAT, countries } from 'jsvat'
import XLSX from 'xlsx'
import { isEmpty } from 'lodash';
export default {
    data() {
        return {
            search: null,
            selected_header: 'customer_name',
            color: 'error',
            disabled: true,
            new_item: false,
            customer_valid: true,
            dialog: false,
            err_dialog: false,
            stepper: 1,
            sameAsAddress: false,
            step_1_valid: true,
            step_2_valid: true,
            step_3_valid: true,
            step_4_valid: true,
            step_5_valid: true,
            step_6_valid: true,
            step_7_valid: true,
            account_managers: [
                'WebOrders',
                'Jeroen Gijzen',
                'Niek Fleskens',
                'Thijs Huijs',
                'Nick Visser',
                'Jip Adriaans',
                'Gerjan Aalbers',
                'Shahin Rasti',
                'Leo van der Putten',
                'Simone Nieland',
                'Guy Bilsen',
                'Wesley Sallons',
                'Nick Visser',
            ],
            status: [
                { key: 'prospect', value: 'prospect', label: 'Prospect' },
                { key: 'customer', value: 'customer', label: 'Customer' },
                { key: 'lost', value: 'lost', label: 'Lost' },
            ],
            customer_details_valid: true,
            snackbar: {
                state: false,
                color: '',
                message: '',
                timeout: 5000,
                multiline: true,
                persistent: false,
            },
            headers: [
                { text: 'Customer', value: 'customer_name' },
                { text: 'Status', value: 'status', align: 'center' },
                { text: 'Salesforce ID', value: 'salesforce_id' },
                { text: 'Contact Person', value: 'contact_person' },
                { text: 'Phone Number', value: 'phone_number' },
                { text: 'Account Manager', value: 'account_manager' },
                { text: 'Support Mail', value: 'support_mail' },
                { text: 'Bundle Trigger Mail', value: 'bundle_trigger_mail' },
                { text: 'Test Ready Telenor', value: 'test_ready_period_telenor' },
                { text: 'Test Ready KPN', value: 'test_ready_period_kpn' },
                { text: 'Test Ready Tele2', value: 'test_ready_period_tele2' },
                { text: 'Test Ready T-Mobile', value: 'test_ready_period_tmobile' },
                { text: 'Contract Term Telenor', value: 'contract_term_telenor' },
                { text: 'Contract Term KPN', value: 'contract_term_kpn' },
                { text: 'Contract Term Tele2', value: 'contract_term_tele2' },
                { text: 'Contract Term T-Mobile', value: 'contract_term_tmobile' },
                { text: 'Premium Code', value: 'support_24x7' },
                { text: 'Created', value: 'created' },
                { text: 'Updated', value: 'updated' },
            ],
            selected: [],
            support_24x7: '',
            required_vat_code: [7],
            vat_code_items: [
                { name: 'Geen BTW', id: 0 },
                { name: 'BTW laag tarief (Exclusief)', id: 1 },
                { name: 'BTW hoog tarief (Exclusief)', id: 2 },
                { name: 'BTW laag tarief (Inclusief)', id: 3 },
                { name: 'BTW hoog tarief (Inclusief)', id: 4 },
                { name: 'BTW verlegd (inkoop)', id: 5 },
                { name: 'Verkopen buiten de EU', id: 6 },
                { name: 'Verkopen binnen de EU', id: 7 },
                { name: 'Installatie / televerkoop in EG', id: 8 },
                { name: 'Verwerving buiten EU (invoer)', id: 9 },
                { name: 'Verwerving binnen EU', id: 10 },
                { name: 'BTW Prive gebruik', id: 11 },
                { name: 'BTW overige tarieven', id: 12 },
                { name: 'BTW verlegd (verkoop)', id: 13 },
                { name: 'BTW 0% (alleen grondslag)', id: 20 },
            ],
            payment_methods: [
                { name: 'Bank Transfer', id: 0 },
                { name: 'SEPA', id: 1 },
            ],
            payment_days: [
                { id: 7, name: '7 Days' },
                { id: 8, name: '8 Days' },
                { id: 14, name: '14 Days' },
                { id: 21, name: '21 Days' },
                { id: 30, name: '30 Days' },
                { id: 60, name: '60 Days' },
                { id: 90, name: '90 Days' },
            ],
            specials: [],
            editedItem: {
                force_creation: false,
                customer_name: '',
                support_mail: '',
                contact_person: '',
                account_manager: '',
                test_ready_period_telenor: 90,
                test_ready_period_kpn: 90,
                test_ready_period_tele2: 90,
                test_ready_period_tmobile: 90,
                test_ready_period_vodafone: 90,
                test_ready_period_conexa_ld: 90,
                test_ready_period_conexa_os: 90,
                contract_term_telenor: 730,
                contract_term_kpn: 365,
                contract_term_tele2: 365,
                contract_term_tmobile: 365,
                contract_term_vodafone: 365,
                contract_term_conexa_ld: 365,
                contract_term_conexa_os: 365,
                support_24x7: 'No',
                shipping_name: '',
                shipping_address: '',
                shipping_city: '',
                shipping_postal_code: '',
                shipping_country: '',
                invoice_address: '',
                invoice_city: '',
                invoice_country: 'Nederland',
                invoice_mail: '',
                invoice_name: '',
                invoice_postal_code: '',
                invoice_reference: '',
                status: 'prospect',
                vat_number: '',
                vat_code: 2,
                iban: '',
                salesforce_id: '',
                payment_method: { name: 'SEPA', id: 1 },
                payment_days: { id: 14, name: '14 Days' },
                shipping_fee: 15.95,
                specials: [],
            },
            rules: {
                required: (value) => !!value || 'Required.',
                invoiceRequired: (value) => {
                    if (this.editedItem.status !== 'prospect') {
                        return !!value || 'Required.'
                    }
                    return true
                },
                number_required: (value) => value !== '' || 'Required.',
                number: (value) => (!isNaN(value) && value > -1) || 'Can only contain positive numbers.',
                min: (value) => value.length >= 8 || 'Minimum of 8 characters.',
                phone: (value) => !value || this.validatePhoneFormat(value) || 'Invalid Phone Nubmer',
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
                invoiceEmail: (value) => {
                    if (this.editedItem.status !== 'prospect') {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    }
                    return true
                },
                email_list: (value) => !value || this.validateEmailList(value) || 'Invalid e-mail address in list!',
                currency: (value) => {
                    const pattern = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/
                    return pattern.test(value) || 'Invalid currency format!'
                },
                ibanRequired: (value) => {
                    if (this.editedItem.status !== 'prospect' && this.editedItem.payment_method.id == 1) {
                        return !!value || 'Required.'
                    }
                    return true
                },
                iban: (value) => !value || this.validateIbanFormat(value) || 'Invalid IBAN Number',
                vatRequired: (value) => {
                    if (this.editedItem.status !== 'prospect' && this.editedItem.vat_code == 7) {
                        return !!value || 'Required.'
                    }
                    return true
                },
                vat: (value) => !value || this.validateVatFormat(value) || 'VAT Identification Number is invalid!',
                sfid: (value) => {
                    const pattern = /^[S,F,0-9]{8,18}$/
                    return pattern.test(value) || 'Invalid Salesforce ID'
                },
            },
            country_list: [
                { value: 'AL', text: 'Albanie' },
                { value: 'AD', text: 'Andorra' },
                { value: 'AT', text: 'Oostenrijk' },
                { value: 'BY', text: 'Wit-Rusland' },
                { value: 'BE', text: 'Belgie' },
                { value: 'BA', text: 'Bosnie en Herzegovina' },
                { value: 'BG', text: 'Bulgarije' },
                { value: 'HR', text: 'Kroatie' },
                { value: 'CY', text: 'Cyprus' },
                { value: 'CZ', text: 'Tsjechie' },
                { value: 'CW', text: 'Curaçao'},
                { value: 'DK', text: 'Denemarken' },
                { value: 'EE', text: 'Estland' },
                { value: 'FO', text: 'Faeroer' },
                { value: 'FI', text: 'Finland' },
                { value: 'FR', text: 'Frankrijk' },
                { value: 'GE', text: 'Georgie' },
                { value: 'DE', text: 'Duitsland' },
                { value: 'GI', text: 'Gibraltar' },
                { value: 'GR', text: 'Griekenland' },
                { value: 'HU', text: 'Hongarije' },
                { value: 'IS', text: 'IJsland' },
                { value: 'IE', text: 'Ierland' },
                { value: 'IT', text: 'Italie' },
                { value: 'KZ', text: 'Kazachstan' },
                { value: 'LV', text: 'Letland' },
                { value: 'LI', text: 'Liechtenstein' },
                { value: 'LT', text: 'Litouwen' },
                { value: 'LU', text: 'Luxemburg' },
                { value: 'MK', text: 'Noord-Macedonie' },
                { value: 'MT', text: 'Malta' },
                { value: 'MD', text: 'Moldavie' },
                { value: 'MC', text: 'Monaco' },
                { value: 'NL', text: 'Nederland' },
                { value: 'NO', text: 'Noorwegen' },
                { value: 'PL', text: 'Polen' },
                { value: 'PT', text: 'Portugal' },
                { value: 'RO', text: 'Roemenie' },
                { value: 'SM', text: 'San Marino' },
                { value: 'SK', text: 'Slowakije' },
                { value: 'SI', text: 'Slovenie' },
                { value: 'ES', text: 'Spanje' },
                { value: 'SE', text: 'Zweden' },
                { value: 'CH', text: 'Zwitserland' },
                { value: 'TR', text: 'Turkije' },
                { value: 'UA', text: 'Oekraine' },
                { value: 'GB', text: 'Verenigd Koninkrijk' },
                { value: 'VA', text: 'Vaticaanstad' },
                { value: 'US', text: 'Verenigde Staten'},
            ],
            vat_countries: [],
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            customers: (state) => state.calls.customer_list,
            
        }),
        filteredItems() {
            if (!this.search || !this.selected_header) {
                return this.customerItems
            }

            const searchTerm = this.search.toLowerCase()
            const headerValue = this.selected_header

            let filter = _.filter(this.customerItems, (item) => {
                const headerText = item[headerValue]?.toString().toLowerCase()

                if (headerValue === 'prospect') {
                    if (searchTerm === 'customer') {
                        return item.status === 'customer'
                    } else if (searchTerm === 'prospect') {
                        return item.status === 'prospect'
                    } else {
                        return false
                    }
                } else {
                    if (!item.hasOwnProperty(headerValue)) return false
                    return headerText.includes(searchTerm)
                }
            })
            return filter
        },
        toggleActionsButton() {
            return this.selected.length <= 0
        },
        customerLoading() {
            return this.customers ? this.customers.pending : true
        },
        customerItems() {
            return this.customers ? this.customers.payload : []
        },
        saveButtonState() {
            if (this.selected.length <= 1) {
                let check
                if (this.editedItem.status == 'prospect') {
                    if (
                        this.editedItem.customer_name &&
                        this.editedItem.support_mail &&
                        this.editedItem.shipping_name &&
                        this.editedItem.shipping_address &&
                        this.editedItem.shipping_city &&
                        this.editedItem.shipping_postal_code &&
                        this.editedItem.shipping_country &&
                        this.editedItem.contract_term_kpn.toString().length > 0 &&
                        this.editedItem.contract_term_tele2.toString().length > 0 &&
                        this.editedItem.contract_term_telenor.toString().length > 0 &&
                        this.editedItem.contract_term_tmobile.toString().length > 0
                    ) {
                        check = false
                    } else {
                        check = true
                    }
                } else if (
                    this.editedItem.customer_name &&
                    this.editedItem.support_mail &&
                    this.editedItem.shipping_name &&
                    this.editedItem.shipping_address &&
                    this.editedItem.shipping_city &&
                    this.editedItem.shipping_postal_code &&
                    this.editedItem.shipping_country
                ) {
                    check = false
                    
                } else {
                    check = true
                    
                }

                if (this.editedItem.vat_code == 0) {
                    if (this.editedItem.vat == '') {
                        check = true
                    } else {
                        check = false
                    }
                }
                
                return check
            }
        },
        ExceptionsDetailsState(){
            if (this.selected.length <=1){
                return this.specials[0].specials
                ? 'mdi-account-alert'
                : 'mdi-alert-box'
            }
        },
        addressDetailsState() {
            if (this.selected.length <= 1) {
                return this.editedItem.shipping_name &&
                    this.editedItem.shipping_address &&
                    this.editedItem.shipping_city &&
                    this.editedItem.shipping_postal_code &&
                    this.editedItem.shipping_country
                    ? 'mdi-truck'
                    : 'mdi-alert-circle'
            }
        },
        invoiceDetailsState() {
            if (this.selected.length <= 1) {
                return this.editedItem.invoice_mail &&
                    this.editedItem.invoice_address &&
                    this.editedItem.invoice_city &&
                    this.editedItem.invoice_postal_code &&
                    this.editedItem.invoice_country
                    ? 'mdi-account-cash'
                    : 'mdi-alert-circle'
            }
        },
        addressIconState() {
            if (this.selected.length <= 1) {
                return this.editedItem.shipping_name &&
                    this.editedItem.shipping_address &&
                    this.editedItem.shipping_city &&
                    this.editedItem.shipping_postal_code &&
                    this.editedItem.shipping_country
                    ? ''
                    : 'warning'
            }
        },
        invoiceIconState() {
            if (this.selected.length <= 1) {
                return this.editedItem.invoice_name &&
                    this.editedItem.invoice_mail &&
                    this.editedItem.invoice_address &&
                    this.editedItem.invoice_city &&
                    this.editedItem.invoice_postal_code &&
                    this.editedItem.invoice_country
                    ? ''
                    : 'warning'
            }
        },
        ExceptionsIconState(){
            if (this.selected.length <=1){
                return specials
                ? ''
                : 'warning'
            }
        },
        setInvoiceDetails() {
            if (this.sameAsAddress == true) {
                this.editedItem.invoice_address = this.editedItem.shipping_address
                this.editedItem.invoice_city = this.editedItem.shipping_city
                this.editedItem.invoice_name = this.editedItem.shipping_name
                this.editedItem.invoice_postal_code = this.editedItem.shipping_postal_code
                this.editedItem.invoice_country = this.editedItem.shipping_country
            }
        },
        prospectColor() {
            var color = 'secondary'
            if (!this.new_item) {
                if (this.editedItem.status == 'prospect') {
                    color = 'info'
                }
            }
            return color
        },
        paymentTerms() {
            var payment_days = []
            if (typeof this.editedItem.payment_method === 'object') {
                switch (this.editedItem.payment_method.id) {
                    case 0:
                        payment_days.push(
                            { id: 7, name: '7 Days' },
                            { id: 8, name: '8 Days' },
                            { id: 14, name: '14 Days' },
                            { id: 21, name: '21 Days' },
                            { id: 30, name: '30 Days' },
                            { id: 60, name: '60 Days' },
                            { id: 90, name: '90 Days' }
                        )
                        break
                    default:
                        payment_days = [
                            { id: 7, name: '7 Days' },
                            { id: 8, name: '8 Days' },
                            { id: 14, name: '14 Days' },
                            { id: 90, name: '90 Days' },
                        ]
                }
            }
            return payment_days.sort((a, b) => a.id - b.id)
        },
        submitButton() {
            var name = ''

            switch (this.editedItem.status == 'prospect') {
                case true:
                    name = 'SUBMIT'
                    break
                default:
                    name = 'CONTINUE'
            }

            return name
        },
    },
    methods: {
        checkIfUpdateIsNeeded(item){
            if (!isEmpty(item)){
                return item
            }
            return this.fillSpecials(item)
        },
        fillSpecials(item) {
            return [{
                category: 'order',
                subcategory: 'regular',
                specials: this.ensureArray(''),
                specialsString:this.editedItem.specials.specialsString
            }];
        },
        toggleSnackbar(persistent=false, color, message) {
            this.snackbar.state = true
            this.snackbar.color = color
            this.snackbar.message = message

            if (persistent == true) {
                this.snackbar.persistent = true
                this.snackbar.timeout = 0
            }
        },
        showEditDialog(new_item = false) {
            if (new_item) {
                this.new_item = true
                this.selected = [this.editedItem.test_ready_period_telenor == 365]
                this.dialog = true
            } else if (this.selected.length > 0) {
                this.new_item = false

                if (this.selected.length == 1) {
                    if ('vat_code' in this.selected[0] == false) {
                        this.selected[0].vat_code = 2
                    }

                    var payment_days = [
                        { id: 7, name: '7 Days' },
                        { id: 8, name: '8 Days' },
                        { id: 14, name: '14 Days' },
                        { id: 21, name: '21 Days' },
                        { id: 30, name: '30 Days' },
                        { id: 60, name: '60 Days' },
                        { id: 90, name: '90 Days' },
                    ]
                    if ('payment_days' in this.selected[0]) {
                        if (typeof this.selected[0].payment_days == 'number') {
                            var index = payment_days.findIndex((p) => p.id == this.selected[0].payment_days)
                            this.selected[0]['payment_days'] = payment_days[index]
                        }
                        
                    }

                    var payment_methods = [
                        { name: 'Bank Transfer', id: 0 },
                        { name: 'SEPA', id: 1 },
                    ]
                    if ('payment_method' in this.selected[0]) {
                        if (typeof this.selected[0].payment_method == 'string') {
                            var index = payment_methods.findIndex((p) => p.name == this.selected[0].payment_method)
                            this.selected[0]['payment_method'] = payment_methods[index]
                        }
                        
                    }

                    this.editedItem = Object.assign({}, this.selected[0])
                    
                    this.selected[0].addresses.forEach((element) => {
                        if (element.category == 'invoice') {
                            this.editedItem.invoice_name = element.name
                            this.editedItem.invoice_address = element.address
                            this.editedItem.invoice_city = element.city
                            this.editedItem.invoice_postal_code = element.postal_code
                            this.editedItem.invoice_country = element.country
                        }
                        if (element.category == 'shipping') {
                            this.editedItem.shipping_name = element.name
                            this.editedItem.shipping_address = element.address
                            this.editedItem.shipping_city = element.city
                            this.editedItem.shipping_postal_code = element.postal_code
                            this.editedItem.shipping_country = element.country
                        }
                    })
                }
                this.dialog = true
            }
        },
                /**
         * Splits a special field (which can be a string or an array of strings)
         * using newline and comma as delimiters, trims each part, and filters out empty strings.
         *
         * @param {string|Array} input - The special field value to split.
         * @return {Array} An array of split lines.
         */
        ensureArray(input) {
            if (!input) return [""];
            
            // If the input is a string, wrap it in an array.
            let specialsArray = typeof input === 'string' ? [input] : input;

            // Process each string in the array.
            return specialsArray
        },
        clickSelect(item) {
            Vue.set(this.selected, 0, item)
        },
        validatePhoneFormat(phone_number) {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            return pattern.test(phone_number)
        },
        validateVatFormat(vat_number) {
            let result = checkVAT(vat_number, this.vat_countries)
            return result.isValid
        },
        validateIbanFormat(iban) {
            const pattern =
                /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/g
            return pattern.test(iban)
        },
        validateEmail(email) {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(email)
        },
        allTrue(arr) {
            return arr.every((element) => this.validateEmail(element) === true)
        },
        validateEmailList(list) {
            var valid = false
            if (typeof list === 'object') {
                if (this.allTrue(list)) {
                    valid = true
                }
            } else {
                var list = list
                    .split(/\s*[\n, ]+\s*/)
                    .join()
                    .split(',')
                if (this.allTrue(list)) {
                    valid = true
                }
            }
            return valid
        },
        validateStep(step = false) {
            if (this.stepper === 1) {
                if (this.$refs.step_1.validate()) {
                    this.step_1_valid = true

                    // Activate stepper
                    if (step) {
                        this.stepper = step
                    }
                } else {
                    this.step_1_valid = false
                }
            } else if (this.stepper === 2) {
                if (this.$refs.step_2.validate()) {
                    this.step_2_valid = true

                    if (this.editedItem.status === 'prospect') {
                        this.save()
                    }

                    // Activate stepper
                    if (step) {
                        this.stepper = step
                    }
                } else {
                    this.step_2_valid = false
                }
            } else if (this.stepper === 3) {
                if (this.$refs.step_3.validate()) {
                    this.step_3_valid = true

                    // Activate stepper
                    if (step) {
                        this.stepper = step
                    }
                } else {
                    this.step_3_valid = false
                }
            } else if (this.stepper === 4) {
                if (this.$refs.step_4.validate()) {
                    this.step_4_valid = true
                    // Activate stepper
                    if (step) {
                        this.stepper = step
                    }
                } else {
                    this.step_4_valid = false
                }
            } else if (this.stepper === 5) {
                if (this.$refs.step_5.validate()) {
                    this.step_5_valid = true

                    //Activate stepper
                    if (step) {
                        this.stepper = step
                    }
                } else {
                    this.step_5.valid = false
                }
            } else if (this.stepper === 6) {
                if (this.$refs.step_6.validate()) {
                    this.step_6_valid = true

                    //Activate stepper
                    if (step) {
                        this.save()
                    }
                } else {
                    this.step_6_valid = false
                }
            }
        },
        validateCustomerEdit() {
            if (this.editedItem.status !== 'prospect') {
                if (this.$refs.edit_customer.validate()) {
                    let support_mail = this.editedItem.support_mail
                        .split(/\s*[\n, ]+\s*/)
                        .join()
                        .split(',')

                    for (var i = 0; i < support_mail.length; i++) {
                        if (support_mail[i] === '') {
                            support_mail.splice(i, 1)
                        }
                    }

                    if (this.validateEmailList(support_mail)) {
                        this.customer_valid = true
                    } else {
                        this.customer_valid = false
                    }
                } else {
                    this.customer_valid = false
                }
                return this.customer_valid
            }
        },
        save(force = false) {
            let support_mail = this.editedItem.support_mail
                    .split(/\s*[\n, ]+\s*/)
                    .join()
                    .split(',')

            for (var i = 0; i < support_mail.length; i++) {
                if (support_mail[i] === '') {
                    support_mail.splice(i, 1)
                }
            }

            let invoice_mail = this.editedItem.invoice_mail
                .split(/\s*[\n, ]+\s*/)
                .join()
                .split(',')
            for (var i = 0; i < invoice_mail.length; i++) {
                if (invoice_mail[i] === '') {
                    invoice_mail.splice(i, 1)
                }
            }

            

            let bundle_trigger_mail = this.editedItem.bundle_trigger_mail
            let iban = this.editedItem.iban

            if (bundle_trigger_mail) {
                bundle_trigger_mail = bundle_trigger_mail
                    .split(/\s*[\n, ]+\s*/)
                    .join()
                    .split(',')

                for (var i = 0; i < bundle_trigger_mail.length; i++) {
                    if (bundle_trigger_mail[i] === '') {
                        bundle_trigger_mail.splice(i, 1)
                    }
                }
            }

            if (iban) {
                iban = iban.replace(/-|\s+/g, '').toUpperCase()
            }

            if (!this.new_item) {
                if (this.$refs.edit_customer.validate()) {
                    const data = {
                        id: this.editedItem.id,
                        force_creation: force,
                        customer_name: this.editedItem.customer_name.split(' (')[0],
                        account_manager: this.editedItem.account_manager,
                        specials: this.checkIfUpdateIsNeeded(this.editedItem.specials),
                        contact_person: this.editedItem.contact_person,
                        phone_number: this.editedItem.phone_number,
                        support_mail: support_mail,
                        support_24x7: this.editedItem.support_24x7,
                        test_ready_period_telenor: this.editedItem.test_ready_period_telenor,
                        test_ready_period_kpn: this.editedItem.test_ready_period_kpn,
                        test_ready_period_tele2: this.editedItem.test_ready_period_tele2,
                        test_ready_period_tmobile: this.editedItem.test_ready_period_tmobile,
                        test_ready_period_vodafone: this.editedItem.test_ready_period_vodafone,
                        test_ready_period_conexa_ld: this.editedItem.test_ready_period_conexa_ld,
                        test_ready_period_conexa_os: this.editedItem.test_ready_period_conexa_os,
                        contract_term_telenor: this.editedItem.contract_term_telenor,
                        contract_term_kpn: this.editedItem.contract_term_kpn,
                        contract_term_tele2: this.editedItem.contract_term_tele2,
                        contract_term_tmobile: this.editedItem.contract_term_tmobile,
                        contract_term_vodafone: this.editedItem.contract_term_vodafone,
                        contract_term_conexa_ld: this.editedItem.contract_term_conexa_ld,
                        contract_term_conexa_os: this.editedItem.contract_term_conexa_os,
                        bundle_trigger_mail: bundle_trigger_mail,
                        shipping_name: this.editedItem.shipping_name,
                        shipping_address: this.editedItem.shipping_address,
                        shipping_city: this.editedItem.shipping_city,
                        shipping_postal_code: this.editedItem.shipping_postal_code,
                        shipping_country: this.editedItem.shipping_country,
                        invoice_name: this.editedItem.invoice_name,
                        invoice_mail: invoice_mail,
                        invoice_address: this.editedItem.invoice_address,
                        invoice_city: this.editedItem.invoice_city,
                        invoice_postal_code: this.editedItem.invoice_postal_code,
                        invoice_country: this.editedItem.invoice_country,
                        purchase_order_reference: this.editedItem.purchase_order_reference,
                        iban: iban,
                        vat_number: this.editedItem.vat_number,
                        vat_code: this.editedItem.vat_code,
                        prospect: this.editedItem.prospect,
                        status: this.editedItem.status,
                        salesforce_id: this.editedItem.salesforce_id,
                        shipping_fee: this.editedItem.shipping_fee.toString(),
                    }
                    switch (this.editedItem.status) {
                        case 'customer':
                            data.direct_debit = this.editedItem.payment_method.id
                            data.payment_days = this.editedItem.payment_days.id
                            break
                    }

                    

                    if (this.selected.length > 0) {
                        this.$store
                            .dispatch('api/call', {
                                method: 'put',
                                url: '/root/customers',
                                data: {
                                    ...data,
                                },
                            })
                            .then(
                                (response) => {
                                    this.toggleSnackbar(false, 'success', response.data.description)
                                    this.selected = []
                                    this.getCustomers()
                                    this.close()
                                },
                                (error) => {
                                    this.toggleSnackbar(true, 'error', this.payload.description)
                                }
                            )
                        this.$refs.edit_customer.resetValidation()
                    }
                } else {
                    this.toggleSnackbar(true, 'error', 'Some fields are not filled correctly!')
                    return
                }
            } else if (this.new_item) {
                const data = {
                        id: this.editedItem.id,
                        force_creation: force,
                        customer_name: this.editedItem.customer_name.split(' (')[0],
                        account_manager: this.editedItem.account_manager,
                        contact_person: this.editedItem.contact_person,
                        phone_number: this.editedItem.phone_number,
                        support_mail: support_mail,
                        specials:  this.checkIfUpdateIsNeeded(this.editedItem.specials),
                        support_24x7: this.editedItem.support_24x7,
                        test_ready_period_telenor: this.editedItem.test_ready_period_telenor,
                        test_ready_period_kpn: this.editedItem.test_ready_period_kpn,
                        test_ready_period_tele2: this.editedItem.test_ready_period_tele2,
                        test_ready_period_tmobile: this.editedItem.test_ready_period_tmobile,
                        test_ready_period_vodafone: this.editedItem.test_ready_period_vodafone,
                        test_ready_period_conexa_ld: this.editedItem.test_ready_period_conexa_ld,
                        test_ready_period_conexa_os: this.editedItem.test_ready_period_conexa_os,
                        contract_term_telenor: this.editedItem.contract_term_telenor,
                        contract_term_kpn: this.editedItem.contract_term_kpn,
                        contract_term_tele2: this.editedItem.contract_term_tele2,
                        contract_term_tmobile: this.editedItem.contract_term_tmobile,
                        contract_term_vodafone: this.editedItem.contract_term_vodafone,
                        contract_term_conexa_ld: this.editedItem.contract_term_conexa_ld,
                        contract_term_conexa_os: this.editedItem.contract_term_conexa_os,
                        bundle_trigger_mail: bundle_trigger_mail,
                        shipping_name: this.editedItem.shipping_name,
                        shipping_address: this.editedItem.shipping_address,
                        shipping_city: this.editedItem.shipping_city,
                        shipping_postal_code: this.editedItem.shipping_postal_code,
                        shipping_country: this.editedItem.shipping_country,
                        invoice_name: this.editedItem.invoice_name,
                        invoice_mail: invoice_mail,
                        invoice_address: this.editedItem.invoice_address,
                        invoice_city: this.editedItem.invoice_city,
                        invoice_postal_code: this.editedItem.invoice_postal_code,
                        invoice_country: this.editedItem.invoice_country,
                        purchase_order_reference: this.editedItem.purchase_order_reference,
                        iban: iban,
                        vat_number: this.editedItem.vat_number,
                        vat_code: this.editedItem.vat_code,
                        prospect: this.editedItem.prospect,
                        status: this.editedItem.status,
                        salesforce_id: this.editedItem.salesforce_id,
                        shipping_fee: this.editedItem.shipping_fee.toString(),
                    }
                    switch (this.editedItem.status) {
                        case 'customer':
                            data.direct_debit = this.editedItem.payment_method.id
                            data.payment_days = this.editedItem.payment_days.id
                            break
                    }

                

                this.$store
                    .dispatch('api/call', {
                        method: 'post',
                        url: '/root/customers',
                        data: { ...data },
                    })
                    .then(
                        (response) => {
                            this.toggleSnackbar(false, 'success', response.data.description)
                            this.getCustomers()
                            this.close()
                        },
                        (error) => {
                            if (this.payload.status == 409) {
                                this.err_dialog = true
                            } else {
                                this.toggleSnackbar(true, 'error', this.payload.description)
                                this.disabled = false
                            }
                        }
                    )
            }
        },
        close() {
            this.dialog = false
            this.err_dialog = false
            this.stepper = 1

            setTimeout(() => {
                this.new_item = false
                this.selected = []
                this.sameAsAddress = false
                this.editedItem = {
                    force_creation: false,
                    customer_name: '',
                    support_mail: '',
                    contact_person: '',
                    account_manager: '',
                    specials: [],
                    test_ready_period_telenor: 90,
                    test_ready_period_kpn: 90,
                    test_ready_period_tele2: 90,
                    test_ready_period_tmobile: 90,
                    test_ready_period_vodafone: 90,
                    test_ready_period_conexa_ld: 90,
                    test_ready_period_conexa_os: 90,
                    contract_term_telenor: 730,
                    contract_term_kpn: 365,
                    contract_term_tele2: 365,
                    contract_term_tmobile: 365,
                    contract_term_vodafone: 365,
                    contract_term_conexa_ld: 365,
                    contract_term_conexa_os: 365,
                    support_24x7: 'No',
                    shipping_name: '',
                    shipping_address: '',
                    shipping_city: '',
                    shipping_postal_code: '',
                    shipping_country: '',
                    invoice_name: '',
                    invoice_reference: '',
                    invoice_mail: '',
                    invoice_address: '',
                    invoice_city: '',
                    invoice_postal_code: '',
                    invoice_country: '',
                    prospect: false,
                    status: 'prospect',
                    vat_number: '',
                    vat_code: 2,
                    iban: '',
                    salesforce_id: '',
                    payment_method: { name: 'SEPA', id: 1 },
                    payment_days: { id: 14, name: '14 Days' },
                    shipping_fee: 15.95,
                }
            }, 300)
        },
        getCustomers() {
            this.$store
                .dispatch('api/call', {
                    name: 'customer_list',
                    url: '/root/customers',
                })
                .then((response) => {
                    this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))

                    for (var i = 0; i < this.customers.payload.length; i++) {
                        this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']})`
                    }
                })
        },
        exportSelected(data) {
            console.log('Exporting...', data)

            // order the keys
            const orderd_keys = [
                'id',
                'salesforce_id',
                'customer_name',
                'account_manager',
                'created',
                'updated',
                'prospect',
                'status',
                'contact_person',
                'support_mail',
                'invoice_mail',
                'contract_term_kpn',
                'contract_term_telenor',
                'contract_term_tele2',
                'contract_term_tmobile',
                'test_ready_period_kpn',
                'test_ready_period_telenor',
                'test_ready_period_tele2',
                'test_ready_period_tmobile',
                'iban',
                'swift',
                'vat_number',
                'vat_code',
                'vat_tariff',
                'payment_method',
                'payment_days',
            ]

            // filter the date based on the orderd keys
            const filtered_data = data.map((item) => {
                const filtered_item = {}
                orderd_keys.forEach((key) => {
                    filtered_item[key] = item[key]
                })
                return filtered_item
            })

            // sort the data based on the prospect value: false.
            filtered_data.sort((a, b) => {

                // define an order for our statuses
                const statusOrder = {
                    'lost': 0,
                    'prospect': 1,
                    'customer': 2,
                }

                if (a.status !== b.status) {
                    return statusOrder[a.status] - statusOrder[b.status]
                }
                return new Date(b.created) - new Date(a.created)
            })

            // Group all non-prospects together (status === customer)
            const customers = filtered_data
                .filter((customer) => customer.status === 'customer')
                .sort((a, b) => new Date(b.created) - new Date(a.created))

            // Group all prospects together (status === prospect)
            const prospects = filtered_data
                .filter((customer) => customer.status === 'prospect')
                .sort((a, b) => new Date(b.created) - new Date(a.created))

            // Group all lost customers together (status === lost)
            // Group all prospects together (prospect === true)
            const lost = filtered_data
                .filter((customer) => customer.status === 'lost')
                .sort((a, b) => new Date(b.created) - new Date(a.created))

            // Merge the two groups together
            const sorted_data = [...customers, ...prospects, ...lost]

            let ws = XLSX.utils.json_to_sheet(sorted_data)
            let wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Customers')
            XLSX.writeFile(wb, 'customers.xlsx')
        },
        customerStatus(status) {
            switch (status) {
                case 'prospect':
                    return {
                        color: 'blue',
                        label: 'Prospect',
                        icon: 'mdi-account-question',
                    }
                case 'customer':
                    return {
                        color: 'green',
                        label: 'Customer',
                        icon: 'mdi-checkbox-marked-circle',
                    }
                case 'lost':
                    return {
                        color: 'red',
                        label: 'Lost',
                        icon: 'mdi-account-off',
                    }
                default:
                    return {
                        color: 'orange',
                        label: 'Unknown',
                        icon: 'mdi-alert-circle',
                    }
            }
        },
        updateSpecialsArray(catIndex) {
            // Split the string by new lines, trim spaces, and remove empty lines
            const lines = this.editedItem.specials[catIndex].specialsString
                .split('\n')
                .map(line => line.trim())
                .filter(line => line.length > 0);

            // Update the specials array
            this.editedItem.specials[catIndex].specials = lines;
        },
        initializeSpecialsString() {
            if (this.editedItem.specials && this.editedItem.specials.length) {
                this.editedItem.specials.forEach((category, index) => {
                    // If category.specialsString doesn't exist yet, create it
                    if (!Object.prototype.hasOwnProperty.call(category, 'specialsString')) {
                        let usedVal = this.ensureArray(category.specials)

                        this.$set(
                            this.editedItem.specials[index], 
                            'specialsString', 
                            category.specials ? usedVal.join('\n') : ''
                        );
                    }
                });
            }
        }    
    },
    watch: {
        'editedItem.specials': {
            deep: true,
            handler() {
                this.initializeSpecialsString();
            }
        },
        selected_header(new_value, old_value) {
            if (new_value !== old_value) {
                this.search = ''
            }
        },
        sameAsAddress() {
            if (this.sameAsAddress == true) {
                this.editedItem.invoice_address = this.editedItem.shipping_address
                this.editedItem.invoice_city = this.editedItem.shipping_city
                this.editedItem.invoice_name = this.editedItem.shipping_name
                this.editedItem.invoice_postal_code = this.editedItem.shipping_postal_code
                this.editedItem.invoice_country = this.editedItem.shipping_country
            } else {
                this.editedItem.invoice_address = ''
                this.editedItem.invoice_city = ''
                this.editedItem.invoice_name = ''
                this.editedItem.invoice_postal_code = ''
                this.editedItem.invoice_country = ''
                this.$refs.step_3.resetValidation()
            }
        },
    },
    created() {
        this.vat_countries = countries.filter((x) => x.name !== 'Brazil')
        this.$store
            .dispatch('api/call', {
                name: 'customer_list',
                url: '/root/customers',
            })
            .then((response) => {
                this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))

                for (var i = 0; i < this.customers.payload.length; i++) {
                    this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']})`
                }
            })
            
    },
}
</script>
